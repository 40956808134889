'use client'

import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { BundleNameView } from '@/features/dev-tools/components/BundleNameView'
import { transformDate } from '@/utils/lib/utils'
import Link from 'next/link'
import { DynamicPicture } from '../atoms/DynamicPicture'
import { AngleRightCircle } from '../uiComponents'

export interface CardInformativeProps {
	bundleName?: string
	variant?: 'highlights'
	image: string
	title: string
	description?: string
	date?: string
	link: string
	onClick?: (event: any) => void
}

const CardInformative = ({ bundleName, variant, image, title, description, date, link, onClick }: CardInformativeProps) => {
	const market = useCurrentMarket()
	let marketLocale = market.language.value
	let dateFormatToShow
	if (date && marketLocale) {
		if (market.language.value.startsWith('en') || market.language.value.startsWith('pt')) {
			marketLocale = 'en_UK'
		} //show correct day format for news section, dd/short Month/yyyy, en-* and pt-PT, pt-BR have issues in Intl.DateTimeFormat
		const formatedMarketLocale = marketLocale.replace('_', '-')
		const formatedDate = date.replaceAll('/', '-')
		dateFormatToShow = transformDate(formatedDate, formatedMarketLocale)
		const dateParts = dateFormatToShow.replaceAll(/[.,]/g, '').split(' ')
		dateFormatToShow = `${dateParts?.[0]} ${dateParts?.[1]}, ${dateParts?.[2]}`
	}

	return (
		<Link prefetch={false} href={link} className={`flex w-full ${variant === 'highlights' ? 'bg-white rounded-md' : ''} overflow-hidden`} onClick={onClick}>
			<div className={`relative min-h-32 ${variant === 'highlights' ? 'min-w-32 2xl:min-w-56' : 'min-w-28'}`}>
				<DynamicPicture image={image} alt="" width={variant === 'highlights' ? 224 : 112} height={variant === 'highlights' ? 200 : 112} className="h-full object-cover object-center" />
			</div>
			<div className={`flex flex-row w-full gap-6 ${variant === 'highlights' ? 'items-center' : ''} p-4`} data-bundle-name={bundleName}>
				<div className="flex flex-col">
					<BundleNameView bundleName={bundleName} />
					<h3 className="text-sm text-black font-bold line-clamp-3 bg-orange-500">{title}</h3>
					{description && <p className={`text-sm text-black ${variant === 'highlights' ? '' : 'font-bold'} mt-2 w-full line-clamp-3`}>{description.replace(/\u202F/g, ' ')}</p>} {/** Replacing \u202F which stands for the charcode 8239 that stands for the narrow no-break space with a simple space **/}
					{dateFormatToShow && <p className="text-sm text-secondary font-semibold mt-auto capitalize">{dateFormatToShow}</p>}
				</div>
				{variant === 'highlights' && (
					<div className="w-6 ml-auto">
						<AngleRightCircle />
					</div>
				)}
			</div>
		</Link>
	)
}

export { CardInformative }
