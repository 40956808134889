'use client'
import { Input } from '@/components/uiComponents'
import React, { useRef, useState } from 'react'
import Select, { SingleValue } from 'react-select'
import simple_plus_icon from '../../../../public/images/simple_plus_icon.png'
import delete_icon_black_background from '../../../../public/images/delete_icon_black_background.png'
import Image from 'next/image'
import { CSSObjectWithLabel } from 'react-select'
// import itinImageMap from '../../../../public/images/mock/itinImageMap.png'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

type SingleIteneraryProps = {
	itenNumber: number
	onRemove: () => void
}

type OptionType = {
	value: string
	label: string
}


const SingleItenerary = ({ itenNumber, onRemove }: SingleIteneraryProps) => {
	const [selectedShip, setSelectedShip] = useState<OptionType | null>(null)
	const [selectedSeason, setSelectedSeason] = useState<OptionType | null>(null)
	const [selectedEmbkPort, setSelectedEmbkPort] = useState<OptionType | null>(null)
	const [selectedPackage, setSelectedPackage] = useState<OptionType | null>(null)
	const [dateRangeValue, setdateRangeValue] = useState<OptionType | null>(null)
	const [textareaText, setTextareaText] = useState<string>('')
	const iteneraryImgRef = useRef<HTMLImageElement>(null)
	const sitecoreStore = useSitecoreState((state) => state)

	const handleShipSelect = (option: SingleValue<OptionType>) => {
		setSelectedShip(option)
		const shipName = document.getElementById('ship-text')
		if (shipName ) {
			shipName.innerHTML = option?.label || ''
		} else {
			const shipName = document.querySelector(`#flyer-itinerary-multiple-${itenNumber} .ship-text`)
			if (shipName) {
				shipName.innerHTML = option?.label || ''
			}
		}
	}

	const handleSeasonSelect = (option: SingleValue<OptionType>) => {
		setSelectedSeason(option)
	}

	const dateRangeSelect = (option: SingleValue<OptionType>) => {
		setdateRangeValue(option)
		const departureDay = document.getElementById('departure-day-text')
		const departureRange = document.getElementById('date-range')
		const daysNightsRange = document.getElementById('days-nights')
		if (departureDay && departureRange && daysNightsRange) {
			departureDay.innerHTML = 'Saturday'
			daysNightsRange.innerHTML = '7 days - 6 nights'
			departureRange.innerHTML = option?.label || ''
		}
	}

	const handlePortSelect = (option: SingleValue<OptionType>) => {
		setSelectedEmbkPort(option)
		const portName = document.getElementById('port-text')
		const iteneraryImage = document.querySelector('#flyer-itinerary-single .itinerary-image') as HTMLImageElement
		if (portName && iteneraryImage && iteneraryImgRef.current) {
			portName.innerHTML = option?.label || ''
			iteneraryImage.src = iteneraryImgRef.current?.src || ''
		} else {
			const iteneraryImage = document.querySelector(`#flyer-itinerary-multiple-${itenNumber} .itinerary-image`) as HTMLImageElement
			if (iteneraryImage) {
				iteneraryImage.src = iteneraryImgRef.current?.src || ''
			}
		}
	}

	const handlePackageSelect = (option: SingleValue<OptionType>) => {
		setSelectedPackage(option);
	
		const promotionText =
			document.getElementById('promotion-text') ||
			document.querySelector(`#flyer-itinerary-multiple-${itenNumber} .promotion-text`);
	
		if (promotionText) {
			promotionText.innerHTML = option?.label.toUpperCase() || '';
		}
	};
	

	const formatPrice = (value: string) => {
		const cleanedValue = value.replace(/[^\d]/g, '')
		return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	}

	const handleTotalPriceOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;
		const formattedValue = formatPrice(newValue);
	
		const totalPrice =
			document.getElementById('total-price') ||
			document.querySelector(`#flyer-itinerary-multiple-${itenNumber} .total-price`);
	
		if (totalPrice) {
			totalPrice.innerHTML = formattedValue;
			setTextareaText(formattedValue);
		}
	}

	// MOCK DATA
	const embkPort = [
		{ value: 'option1', label: 'Genoa' },
		{ value: 'option2', label: 'Napoli' },
		{ value: 'option3', label: 'Roma' },
	]

	const packageToInclude = [
		{ value: 'option1', label: 'WI-FI' },
		{ value: 'option2', label: 'DRINKS' },
		{ value: 'option3', label: 'SPECIALITY RESTAURANTS' },
	]

	const dateRange = [
		{ value: 'option1', label: '14/12/2024 - 2/1/2025' },
		{ value: 'option2', label: '18/12/2024 - 12/8/2025' },
		{ value: 'option3', label: '22/4/2025 - 16/10/2025' },
	]

	const shipAndSeasonData = [
		{
			id: 1,
			options: [
				{ value: 'option1', label: 'MSC Aromonia' },
				{ value: 'option2', label: 'MSC Musica' },
				{ value: 'option3', label: 'MSC Euribia' },
			],
			placeholder: 'Select a ship',
			required: true,
			value: selectedShip,
			onChange: handleShipSelect,
		},
		{
			id: 2,
			options: [
				{ value: 'option1', label: 'Summer' },
				{ value: 'option2', label: 'Winter' },
				{ value: 'option3', label: 'Autumn' },
			],
			placeholder: 'Select a season',
			required: true,
			value: selectedSeason,
			onChange: handleSeasonSelect,
		},
	]
	// MOCK DATA

	const itineraryLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ITINERARY', 'Itinerary')
	const addUpToFiveItinLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-UP-TO-FIVE-ITIN', 'Add up to five different iteneraries')
	const typeThePriceLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-SET-PRICE', 'Set price')

	const customStyles = {
		control: (provided: CSSObjectWithLabel) => ({
			...provided,
			borderRadius: '6px',
		}),
		input: (provided: CSSObjectWithLabel) => ({
			...provided,
			height: '42px',
			borderRadius: '16px',
		}),
		indicatorSeparator: (provided: CSSObjectWithLabel) => ({
			...provided,
			display: 'none',
		}),
	}

	return (
		<div className="flex flex-col gap-10 mb-10">
			<div className="flex flex-col">
				<div className="flex items-center justify-between mb-10">
					<p className="font-bold">{`${itineraryLabel} ${itenNumber}`} </p>
					{itenNumber !== 1 && <Image src={delete_icon_black_background} alt="delete icon" onClick={onRemove} className="cursor-pointer"></Image>}
				</div>
				<div className="flex flex-col gap-6">
					{shipAndSeasonData.map((selectConfig) => (
						<Select key={selectConfig.id} options={selectConfig.options} placeholder={selectConfig.placeholder} required={selectConfig.required} value={selectConfig.value} onChange={selectConfig.onChange} styles={customStyles} />
					))}
					<Select className={`${selectedShip && selectedSeason ? '' : 'hidden'}`} options={embkPort} placeholder={'Select an embarkation port'} required={true} value={selectedEmbkPort} onChange={handlePortSelect} styles={customStyles} />
				</div>
			</div>

			<div className={`flex flex-col ${selectedShip && selectedSeason && selectedEmbkPort ? '' : 'hidden'}`}>
				<p className="mb-4">{addUpToFiveItinLabel}</p>
				<img ref={iteneraryImgRef} src={'https://www.msccruises.com/images/msc/iti/itin/UV8E.jpg'} alt={''} height={193} width={340} className="rounded border-4 border-black inset-0 bg-gradient-to-b from-transparent via-[rgba(0,0,0,0.197621)] to-[#000033] pointer-events-none z-0 mb-6"></img>
				<div className="flex flex-col gap-6">
					<Select options={dateRange} placeholder={'Select a date range'} required={true} styles={customStyles} onChange={dateRangeSelect} value={dateRangeValue}/>
					{/* <Select options={packageToInclude} placeholder={'Select a package to include'} required={true} onChange={handlePackageSelect} value={selectedPackage} styles={customStyles} /> */}
					{/* <hr /> */}
				</div>
				{/* <div className="relative w-full">
					<Input type="text" label={typeThePriceLabel} customClasses="!rounded-md" onChange={handleTotalPriceOnChange} value={textareaText} />
					<div className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
						<Image src={simple_plus_icon} alt="Add" width={16} height={16} />
					</div>
				</div> */}
			</div>
		</div>
	)
}
export { SingleItenerary }
