'use client'

import { ShowBundleNames } from '@/features/dev-tools/components/ShowBundleNames'
import { SitecoreKeysOverride } from '@/features/dev-tools/components/SitecoreKeysOverride'

export interface DevToolsModalTemplateProps {
	buildVersion?: string
}

export const DevToolsModalTemplate = (props: DevToolsModalTemplateProps) => {
	const valid = props.buildVersion && props.buildVersion !== 'Not found'
	const commitHash = valid ? props.buildVersion?.split('-')[0] : undefined
	const pipelineNumber = valid ? props.buildVersion?.split('-')[1] : undefined

	return (
		<div className="flex flex-col w-full justify-between h-[300px]">
			<div>
				<SitecoreKeysOverride />
				<div className="mt-5" style={{ marginLeft: '-1rem' }}>
					<ShowBundleNames />
				</div>
			</div>
			{commitHash && (
				<div>
					<div className="text-sm">commit hash {commitHash}</div>
					<div className="text-sm">pipeline number {pipelineNumber}</div>
				</div>
			)}
		</div>
	)
}
