'use client'
import React from 'react'
import { Button } from '../uiComponents'
import { useModalState } from '@/utils/hooks/useModalState'

// to do , translation
const BuildYourFlyerInstructionTemplate = () => {
	const modal = useModalState()
	return (
		<div className="flex flex-col gap-3">
			<p>Our flyer templates are organized by either Tactical or Product Information, with both vertical and horizontal template versions available. Select the format that works best for you, with each option offering the ability to quickly add your contact details and travel agency information to facilitate clear communication with clients.​</p>
			<ol type="1">
				<li>Tactical Flyer: Designed to convey specific commercial information about destinations and itineraries, this template allows you to add an itinerary map and customize details such as pricing, departure dates, and more. Additionally, you can include a promotional message to highlight a specific offer, with each promo featuring a dedicated logo.</li>
				<li>Product Flyer: The goal of this flyer is to raise awareness and provide detailed, qualitative information about MSC products. This template is content-driven, offering more space to include in-depth information about the product or service you want to promote.​ ​ Once you've chosen your preferred flyer, customize it with your content, save the file, and you're all set to use it</li>
			</ol>
			<span>
				Want to know more? Download our
				<br>
					<a href="/" className="text-sky-700">
						Build your flyer
					</a>
				</br>
				Guide​
			</span>
			<Button className="w-full" variant="filled" onClick={() => modal.close()}>
				close
			</Button>
		</div>
	)
}

export { BuildYourFlyerInstructionTemplate }
