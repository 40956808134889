'use client'
import React, { useEffect, useState } from 'react'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { Button, OrangeCheckbox, SwiperWrapperBYF, InfoIconWithPopover, TextAreaBYF } from '@/components/uiComponents'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { IOption } from '@/features/elastic/store/types'
// import { useElasticFilterState } from '@/features/search-cruise/store/useElasticFilterState'

// mock data
const destinations = [
	{ label: 'WESTERN MEDITERREAN ', value: 'MEDITERRANEAN', checked: false, disabled: false },
	{ label: 'ALASKA', value: 'ALASKA', checked: false, disabled: false },
	{ label: 'ASIA', value: 'ASIA', checked: false, disabled: false },
	{ label: 'BERMUDA, CANADA & NEW ENGLAND', value: 'BERMUDA, CANADA & NEW ENGLAND', checked: false, disabled: false },
	{ label: 'CARIBBEAN & ANTILLES', value: 'CARIBBEAN & ANTILLES', checked: false, disabled: false },
	{ label: 'NORTHERN EUROPE', value: 'NORTHERN EUROPE', checked: false, disabled: false },
	{ label: 'MSC WORLD CRUISE', value: 'MSC WORLD CRUISE', checked: false, disabled: false },
	{ label: 'CANARY ISLANDS AND MADEIRA', value: 'CANARY ISLANDS AND MADEIRA', checked: false, disabled: false },
	{ label: 'SOUTH AFRICA', value: 'SOUTH AFRICA', checked: false, disabled: false },
	{ label: 'GRAND VOYAGES', value: 'GRAND VOYAGES', checked: false, disabled: false },
	{ label: 'SOUTH AMERICA', value: 'SOUTH AMERICA', checked: false, disabled: false },
	{ label: 'DUBAI, ABU DHABI & QATAR', value: 'DUBAI, ABU DHABI & QATAR', checked: false, disabled: false },
]

const imageContainerMock = ['mock/msc-1.jpg', 'mock/msc-2.jpg', 'mock/msc-3.jpg', 'mock/msc-4.jpg']
// mock data

const DestinationStep = () => {
	// const destinations = useElasticFilterState((state) => state.options.area)   *** ACTUAL DATA ***
	const flyerBuilderState = useFlyerBuilderState()
	const sitecoreStore = useSitecoreState((state) => state)
	const [templateTitleBold, setTemplateTitleBold] = useState<boolean>(false)

	const [isImageSelectionVisible, setIsImageSelectionVisible] = useState<boolean>(true)

	const [localTitleText, setLocalTitleText] = useState(flyerBuilderState.steps.destination.titleText || '')
	const [localTitleTextCount, setLocalTitleTextCount] = useState(flyerBuilderState.steps.destination.titleTextlettersCount || 0)

	const [localParagraphText, setLocalParagraphText] = useState(flyerBuilderState.steps.destination.additionalText || '')
	const [localParagraphTextCount, setLocalParagraphTextCount] = useState(flyerBuilderState.steps.destination.additionalTextlettersCount || 0)

	// -------------------------------------------------------------------------------------------------------------
	useEffect(() => {
		const flyerTitleElemnet = document.getElementById('flyer-title')
		const defaultTitleText = flyerTitleElemnet?.textContent || ''
		setLocalTitleText(defaultTitleText)
		setLocalTitleTextCount(defaultTitleText.length)
	}, [])
	// -------------------------------------------------------------------------------------------------------------

	const handleDestinationNameClick = (destinationOption: IOption) => {
		const destinationElement = document.getElementById('choose-destination')
		const destinationElementName = document.getElementById('destination-text')
		if (destinationElement && destinationElementName) {
			destinationElement.innerHTML = destinationOption.label.toUpperCase()
			destinationElementName.innerHTML = destinationOption.label.toUpperCase()
			flyerBuilderState.updateStepData('destination', { destination: destinationOption })
		}
	}

	const onBoldIconClick = () => {
		const flyerTitleElement = document.getElementById('flyer-description')
		if (flyerTitleElement) {
			if (templateTitleBold) {
				flyerTitleElement.style.fontWeight = '300'
			} else {
				flyerTitleElement.style.fontWeight = '500'
			}
		}
		setTemplateTitleBold((prev) => !prev)
	}

	const handleTextAreaOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>, setState: React.Dispatch<React.SetStateAction<string>>, setStateCount: React.Dispatch<React.SetStateAction<number>>, id: string) => {
		const newValue = event.target.value
		setState(newValue)
		setStateCount(newValue.length)
		const elementToEdit = document.getElementById(id)
		if (elementToEdit) {
			let processedText = newValue
			processedText = processedText.replace(/\n/g, '<br>')
			elementToEdit.innerHTML = templateTitleBold ? `<strong>${processedText}</strong>` : processedText
		}
	}

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		flyerBuilderState.updateStepData('destination', { additionalText: localParagraphText })
	// 		flyerBuilderState.updateStepData('destination', { additionalTextlettersCount: localParagraphText.length })
	// 	}, 700)
	// 	return () => clearTimeout(timer)
	// }, [localParagraphText])

	const handlePolicyAcceptCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.target.checked = !flyerBuilderState.steps.destination.isPolicyAccepted
		flyerBuilderState.updateStepData('destination', { isPolicyAccepted: !flyerBuilderState.steps.destination.isPolicyAccepted })
	}

	const visibleTermsAndConditionsLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-VISIBLE-TERMS-AND-CONDITIONS', 'I agree not to misuse or sell MSC Cruises trademarks, logos, or assets.')
	const addParagraphLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-PARAGRAPH_DESTINATION-STEP', 'Copy')
	const editTitleLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-EDIT_TITLE', 'Edit Headline')
	const selectAnImageLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-AN-IMAGE', 'Select an image')
	const chooseADestinationLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-CHOOSE-A-DESTINATION', 'Choose a destination')
	const termsAndConditionsTitleLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-TERMS-AND-CONDITIONS-TITLE', 'Terms and conditions')
	const textCountLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADDITIONAL-TEXT', 'Text count')
	const termsAndConditionsBodyLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-TERMS-AND-CONDITIONS-BODY', 'The MSC Cruises trademarks, the logo and all other distinguishing symbols of MSC Cruises S.A. are registered rights and therefore the exclusive property of MSC Cruises S.A. Any non-authorized use thereof is not permitted. The user undertakes not to sell or in any other way misuse the access to and/or the right to use the material and the visual assets included.​')

	return (
		<div className="flex flex-col gap-4 w-[372px]">
			<h2 className="text-sm font-bold uppercase">{chooseADestinationLabel}</h2>
			<div className={`grid grid-cols-2 gap-3 pb-6 ${!flyerBuilderState.steps.destination.destination ? 'border-b-[1px] border-[#BDBDBD]' : ''}`}>
				{destinations?.map((destination, index) => {
					return (
						<Button
							key={index}
							onClick={() => {
								handleDestinationNameClick(destination)
							}}
							variant={flyerBuilderState.steps.destination.destination?.value === destination.value ? 'filledSecondary' : 'outlinedTertiary'}
						>
							<span className="text-sm leading-5">{destination.label}</span>
						</Button>
					)
				})}
			</div>
			<div className={!flyerBuilderState.steps.destination.destination ? 'hidden' : 'flex flex-col gap-4'}>
				<div className="flex items-center justify-between">
					<h2 className="text-sm font-bold uppercase">{selectAnImageLabel}</h2>
					<MdOutlineKeyboardArrowRight
						onClick={() => setIsImageSelectionVisible(!isImageSelectionVisible)}
						className="cursor-pointer"
						size={22}
						style={{
							transform: isImageSelectionVisible ? 'rotate(90deg)' : 'rotate(270deg)',
							transition: 'transform 0.3s ease',
						}}
					/>
				</div>
				<div className={isImageSelectionVisible ? 'flex gap-2' : 'hidden'}>
					<SwiperWrapperBYF imageContainerMock={imageContainerMock} htmlElementIdToChangeIcon="flyer-visual-img" />
				</div>
				<div className="flex">
					<OrangeCheckbox isChecked={flyerBuilderState.steps.destination.isPolicyAccepted} handleIsChecked={handlePolicyAcceptCheckbox} textNextToCheckbox={visibleTermsAndConditionsLabel} />
					<InfoIconWithPopover className="ml-1 relative right-[150px] top-[18px]" popoverMessageTitle={termsAndConditionsTitleLabel} popoverMessageBody={termsAndConditionsBodyLabel} />
				</div>
				<hr />
				<TextAreaBYF textAreaLabel={editTitleLabel} characterCounterText={textCountLabel} currentTypedCharacters={localTitleTextCount} textAreaMaxLength={90} value={localTitleText?.toUpperCase()} onChange={(event) => handleTextAreaOnChange(event, setLocalTitleText, setLocalTitleTextCount, 'flyer-title')} rows={2} />
				<hr />
				<TextAreaBYF textAreaLabel={addParagraphLabel} characterCounterText={textCountLabel} currentTypedCharacters={localParagraphTextCount} textAreaMaxLength={250} value={localParagraphText} onChange={(event) => handleTextAreaOnChange(event, setLocalParagraphText, setLocalParagraphTextCount, 'flyer-description')} rows={2} isBoldIconVisible={true} onBoldIconClick={() => onBoldIconClick()} />
			</div>
		</div>
	)
}

export { DestinationStep }
