import { ChangePasswordModalTemplate, ChangePasswordModalTemplateProps } from '@/components/templates/ChangePasswordModalTemplate'
import { CooperaModalTemplate } from '@/components/templates/CooperaModalTemplate'
import { DevToolsModalTemplate, DevToolsModalTemplateProps } from '@/components/templates/DevToolsModalTemplate'
import { ImportantInformationsModalTemplate, ImportantInformationsModalTemplateProps } from '@/components/templates/ImportantInformationsModalTemplate'
import { InfoModalTemplate, InfoModalTemplateProps } from '@/components/templates/InfoModalTemplate'
import { LoginModalTemplate, LoginModalTemplateProps } from '@/components/templates/LoginModalTemplate'
import { NewConsentModalTemplate } from '@/components/templates/NewConsentModalTemplate'
import { NotificationAttachmentModalTemplate, NotificationAttachmentModalTemplateProps } from '@/components/templates/NotificationAttachmentTemplate'
import { UpdateEmailModalTemplate } from '@/components/templates/UpdateEmailModalTemplate'
import { BuildYourFlyerInstructionTemplate, ContactUsModalTemplate, RegisterYourAgencyModalTemplate, SuccessfulRequestModalTemplate, VoyagerCardTemplate, VoyagerCardTemplateProps } from '@/components/uiComponents'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { ReactNode } from 'react'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { enableScrollBehavior } from '../lib/utils'

interface Title {
	bundle: TranslationBundles
	keyName: any
	defaultValue: string
}

type FunctionWithProps<T> = (props?: T, options?: Partial<Options>) => void
type FunctionWithMandatoryProps<T> = (props: T, options?: Partial<Options>) => void

export interface ModalState {
	isOpen: boolean
	title: Title | undefined
	template: ReactNode | undefined
	options: Partial<Options>

	open: (bundle: TranslationBundles, key: string, defaultValue: string, template: ReactNode, options?: Partial<Options>) => void
	close: () => void

	openInfoModal: FunctionWithMandatoryProps<InfoModalTemplateProps>
	openLoginModal: FunctionWithProps<LoginModalTemplateProps>
	openChangePasswordModal: FunctionWithMandatoryProps<ChangePasswordModalTemplateProps>
	openRegisterYourAgencyModal: () => void
	openContactUsModal: () => void
	openDevToolsModal: FunctionWithMandatoryProps<DevToolsModalTemplateProps>
	openNotificationAttachmentModal: FunctionWithMandatoryProps<NotificationAttachmentModalTemplateProps>
	openVoyagerCardModal: FunctionWithMandatoryProps<VoyagerCardTemplateProps>
	openUpdateEmailModal: () => void
	openImportantInformationsModal: FunctionWithMandatoryProps<ImportantInformationsModalTemplateProps>
	openNewConsentModal: () => void
	openSuccessfulRequestModalTemplate: () => void
	openCooperaModal: () => void
	openBuildYourFlyerInstructionTemplate: () => void
}

const initialState = {
	isOpen: false,
	title: undefined,
	template: undefined,
	options: {
		preventClose: false,
		size: undefined,
		modalSize: 'sm',
		modalType: '',
	},
}

export interface Options {
	preventClose: boolean
	size: { width: string; height: string; modalSize: string }
	modalType: string
}

export const useModalState = create<ModalState>()(
	immer((set, get) => ({
		...initialState,

		open: (bundle, keyName, defaultValue, template, options) =>
			set(() => ({
				isOpen: true,
				title: { bundle, keyName, defaultValue } as Title,
				template,
				options: {
					preventClose: options?.preventClose || initialState.options.preventClose,
					size: options?.size || initialState.options.size,
					modalType: options?.modalType || initialState.options.modalType,
				},
			})),

		close: () => {
			enableScrollBehavior()
			set({
				isOpen: false,
				title: undefined,
				template: undefined,
			})
		},

		openInfoModal: (props) => get().open(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_INFORMATION', 'Info', <InfoModalTemplate {...props} />),
		openLoginModal: (props) => get().open(TranslationBundles.WELCOME, 'MSC-EXTERNAL-LOGIN-SECTION-TITLE', 'Login', <LoginModalTemplate {...props} />, { modalType: 'loginModal' }),
		openChangePasswordModal: (props) => get().open(TranslationBundles.HOME, 'MSC-HOMEPAGE-CHANGE-PASSWORD', 'Change Password', <ChangePasswordModalTemplate {...props} />),
		openRegisterYourAgencyModal: () => get().open(TranslationBundles.SIGNIN, 'MSC-SIGNIN_SIGNUP_REGISTER-TITLE', 'Sign up your agency', <RegisterYourAgencyModalTemplate />),
		openContactUsModal: () => get().open(TranslationBundles.HOME, 'MSC-HOMEPAGE_CUSTOMERSUPPORT-INFO_REQUEST', 'Contact us', <ContactUsModalTemplate />),
		openDevToolsModal: (props) => get().open(TranslationBundles.HOME, '', 'Developer Tools', <DevToolsModalTemplate {...props} />),
		openNotificationAttachmentModal: (props) => get().open(TranslationBundles.NOTIFICATIONS, '', 'Attachment', <NotificationAttachmentModalTemplate {...props} />),
		openVoyagerCardModal: (props) => get().open(TranslationBundles.NOTIFICATIONS, 'MSC-HOMEPAGE-MSC_MEMBER_STATUS', 'Member Status', <VoyagerCardTemplate {...props} />),
		openUpdateEmailModal: () => get().open(TranslationBundles.POPUP_REMINDER, 'MSC_POPUP_REMINDER_MESSAGE', 'Set an email to your account', <UpdateEmailModalTemplate />, { preventClose: true }),
		openImportantInformationsModal: (props, options) => get().open(TranslationBundles.HOME, 'MSC-HOMEPAGE-INFORMATIVE_POPUP', 'Important informations', <ImportantInformationsModalTemplate {...props} />, options),
		openNewConsentModal: () => get().open(TranslationBundles.HOME, 'MSC-HOMEPAGE-NEW_CONSENT_TITLE', 'New consent to accept', <NewConsentModalTemplate />),
		openSuccessfulRequestModalTemplate: () => get().open(TranslationBundles.HOME, 'info keyword', 'Information', <SuccessfulRequestModalTemplate />),
		openCooperaModal: () => get().open(TranslationBundles.HOME, 'MSC-HOMEPAGE_COOPERA_CONTRACT_DATE', 'A new contract is available', <CooperaModalTemplate />),
		openBuildYourFlyerInstructionTemplate: () => get().open(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-HOMEPAGE_COOPERA_CONTRACT_DATE', 'Instructions for Creating Your Flyer​', <BuildYourFlyerInstructionTemplate />),
	}))
)
