'use client'
import React, { useEffect, useState } from 'react'
import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { TextAreaBYF } from '../_exporter'

const NotesStep = () => {
	const flyerBuilderState = useFlyerBuilderState()
	const sitecoreStore = useSitecoreState((state) => state)
	const [localText, setLocalText] = useState(flyerBuilderState.steps.notes.additionalNote || '')
	const [localTextCount, setLocalTextCount] = useState(flyerBuilderState.steps.notes.helperText || 0)

	const addParagraphLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD_PARAGRAPH-ADDITIONAL_NOTES_STEP', 'Add an additional note')
	const addAdditionalNotesLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-ADDITIONAL-NOTES', 'Add additional notes')
	const helperTextLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-HELPER-TEXT', 'Additional text')

	const handleAdditionalParagraphOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const newValue = event.target.value
		setLocalText(newValue);
		setLocalTextCount(newValue.length)
		const flyerDescription = document.getElementById('below-price-paragraph')
		if (flyerDescription) {
			flyerDescription.innerHTML = newValue
			
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			flyerBuilderState.updateStepData('notes', { additionalNote: localText })
			flyerBuilderState.updateStepData('notes', { helperText: localText.length })
		}, 700); 
	
		return () => clearTimeout(timer); 
	}, [localText]);

	return (
		<div className='w-[372px]'>
			<h2 className="text-sm font-bold uppercase mb-[72px]">{addAdditionalNotesLabel}</h2>
			<TextAreaBYF textAreaLabel={addParagraphLabel} characterCounterText={helperTextLabel} currentTypedCharacters={localTextCount} textAreaMaxLength={50} value={localText} onChange={handleAdditionalParagraphOnChange} rows={4}/>
		</div>
	)
}

export { NotesStep }
