'use client'

import AgentService from '@/features/agent/agent-service'
import { PassengerSearchByDateResponse } from '@/features/agent/model/model'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { FILTER_TYPE } from '@/features/elastic/store/types'
import { useElasticFilterState } from '@/features/elastic/store/useElasticFilterState'
import HeaderMenuService from '@/features/header-menu/header-menu-service'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import { useRouter } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoSearchSharp } from 'react-icons/io5'
import FormFactory, { SchemaItem } from '../factory/formFactory'
import { Button, DatePickerWrapper, DropdownFilter, FiltersIcon, GuestCabinPicker, SearchCruiseFilters, ShipIcon, TabContent, Tabs, VoyagerIcon } from '../uiComponents'

interface CruiseFormData {
	departureFrom: Date | string
	departureTo: Date | string
	adults: number
	children: number
	juniorChildren: number
	infants: number
	cabins: number
}

export interface VoyagerClubFormData {
	mscCardName: string
	mscCardSurname: string
	mscCardNumberOrBirthDate: string
}

export const SearchCruises = () => {
	const user = useLoggedUser()
	const router = useRouter()
	const market = useCurrentMarket()
	const provideUrl = useUrlProvider()
	const sitecoreState = useSitecoreState((state) => state)

	const [showVoyagerErrorMessage, setShowVoyagerErrorMessage] = useState<boolean>(false)
	const [openAllFilters, setOpenAllFilters] = useState(false)
	const [activeFiltersIndex, setActiveFiltersIndex] = useState(0)

	// eslint-disable-next-line prettier/prettier
	const { options, setOccupancy, getCanUpdateOccupancy, departureTo, departureFrom, searchUrlParams } = useElasticFilterState((state) => {
		return {
			options: state.options,
			setOccupancy: state.setOccupancy,
			getCanUpdateOccupancy: state.getCanUpdateOccupancy,
			departureTo: state.options.departureTo,
			departureFrom: state.options.departureFrom,
			searchUrlParams: state.getUrl(),
		}
	})
	// Form hook for Search Cruises form
	const {
		control: cruiseControl,
		formState: { isSubmitting },
		handleSubmit: handleCruiseSubmit,
		reset: resetCruiseForm,
	} = useForm<CruiseFormData>({
		defaultValues: {
			departureFrom: options.departureFrom,
			departureTo: options.departureTo,
			adults: options.adults,
			children: options.children,
			juniorChildren: options.juniorChildren,
			infants: options.infants,
		},
	})

	// Form hook for MSC Voyagers Club form
	const {
		control: voyagerControl,
		formState: { errors: voyagerErrors },
		handleSubmit: handleVoyagerSubmit,
	} = useForm<VoyagerClubFormData>({
		defaultValues: {
			mscCardName: '',
			mscCardSurname: '',
			mscCardNumberOrBirthDate: '',
		},
	})

	useEffect(() => {
		resetCruiseForm({
			departureFrom: options.departureFrom,
			departureTo: options.departureTo,
			adults: options.adults,
			children: options.children,
			juniorChildren: options.juniorChildren,
			infants: options.infants,
		})
	}, [options, resetCruiseForm])

	const mscCardNameLabel = sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-CABINSELECTION_CONFIRMBOOKING-FIRST_NAME_NEW', 'First name')
	const mscCardSurnameLabel = sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-CABINSELECTION_CONFIRMBOOKING-LAST_NAME_NEW', 'Last name')
	const mscCardNumberOrBirthDateLabel = sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-CABINSELECTION-OR-CARD_NUMER', 'or card number')

	const searchVoyagersSchema: SchemaItem[] = [
		{ type: 'text', name: 'mscCardName', label: mscCardNameLabel, required: false },
		{ type: 'text', name: 'mscCardSurname', label: mscCardSurnameLabel, required: false },
		{ type: 'text', name: 'mscCardNumberOrBirthDate', label: `dd/mm/yyyy ${mscCardNumberOrBirthDateLabel}`, required: false },
	]

	const onSubmitCruise = () => {
		const processedSearchUrls = provideUrl('search-cruise', searchUrlParams)
		router.push(processedSearchUrls)
	}

	const onSubmitVoyagerClub = async (data: VoyagerClubFormData) => {
		try {
			const res = await AgentService.searchVoyagerCard(data)

			if (res.success) {
				useModalState.getState().openVoyagerCardModal(res.data as PassengerSearchByDateResponse['paxDetails'])
				setShowVoyagerErrorMessage(false)
			} else {
				setShowVoyagerErrorMessage(true)
				sendVoyagerClubGA4Event('modalerrors', 'modal_errors_voyagers', 'Member not found')
			}
		} catch {
			setShowVoyagerErrorMessage(true)
			sendVoyagerClubGA4Event('modalerrors', 'modal_errors_voyagers', 'Member not found')
		}
	}

	const guestOptions = [
		{
			label: sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-SEARCH_ADULT_LABEL', 'Adult'),
			value: options.adults,
			onIncrement: () => setOccupancy(FILTER_TYPE.ADULT, 1),
			onDecrement: () => setOccupancy(FILTER_TYPE.ADULT, -1),
			isDecrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.ADULT).remove,
			isIncrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.ADULT).add,
		},
		{
			label: sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-SEARCH_CHILD_LABEL', 'Child'),
			value: options.children,
			onIncrement: () => setOccupancy(FILTER_TYPE.CHILDREN, 1),
			onDecrement: () => setOccupancy(FILTER_TYPE.CHILDREN, -1),
			isDecrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.CHILDREN).remove,
			isIncrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.CHILDREN).add,
		},
		{
			label: sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-SEARCH_JUNIOR_LABEL', 'Junior'),
			value: options.juniorChildren,
			onIncrement: () => setOccupancy(FILTER_TYPE.JUNIOR_CHILDREN, 1),
			onDecrement: () => setOccupancy(FILTER_TYPE.JUNIOR_CHILDREN, -1),
			isDecrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.JUNIOR_CHILDREN).remove,
			isIncrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.JUNIOR_CHILDREN).add,
		},
		{
			label: sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-SEARCH_INFANT_LABEL', 'Infant'),
			value: options.infants,
			onIncrement: () => setOccupancy(FILTER_TYPE.INFANT, 1),
			onDecrement: () => setOccupancy(FILTER_TYPE.INFANT, -1),
			isDecrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.INFANT).remove,
			isIncrementDisabled: !getCanUpdateOccupancy(FILTER_TYPE.INFANT).add,
		},
	]

	const totalPassengers = options.adults + options.children + options.juniorChildren + options.infants
	const isSearchButtonEnabled = Boolean(departureTo && departureFrom)

	// translation labels

	const occupancyLabelSingular = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-OCCUPANCY-PASSENGER_SINGULAR', 'Occupancy')
	const occupancyLabelPlural = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-OCCUPANCY-PASSENGER_PLURAL', 'Passenger')
	const occupancyLabelCabinSingular = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-OCCUPANCY-CABIN_SINGULAR', 'Cabins')

	const searchCruisesLabel = sitecoreState.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-UPDATE_NEW', 'Search Cruises')
	const searchVoyagerClubLabel = sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_CLUB_SEARCH_NEW', 'Search MSC Voyagers Club Member')
	const discoverProgramLabel = sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_CLUB_DISCOVER_PROGRAM', 'Discover program')
	const checkMemberStatusLabel = sitecoreState.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_CHECK_MEMBER_STATUS', 'Check Member status')

	const voyagerMemberNotFound = sitecoreState.getLabel(TranslationBundles.HOME, 'MENU_MSC_VOYAGERS_MEMBER_NOT_FOUND', 'Socio MSC Voyagers Club non trovato')
	const pickersWrapperRef = useRef<HTMLDivElement>(null)

	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendVoyagerClubGA4Event = (event: string, eventName: string, errorOrLabelClicked: string) => {
		window.dataLayer = window.dataLayer || []
		const errorOrLabel = event === 'modalerrors' ? 'error_type' : 'label_clicked'
		window.dataLayer.push({
			event: event,
			eventName: eventName,
			...staticParams,
			[errorOrLabel]: errorOrLabelClicked,
		})
	}

	const handleDiscoverProgramClick = async () => {
		sendVoyagerClubGA4Event('discoverstatusmscvcm', 'discover_program', 'MSC Voyagers Club Cruises')
		const menuItem = await HeaderMenuService.getLinkFromInternalKey('MENU_MSC_VOYAGERS_CLUB', market)
		router.push(menuItem.link)
	}

	return (
		<div className="flex relative flex-col h-full bg-white drop-shadow-lg rounded-sm z-10 px-6 pt-6">
			<Tabs>
				{/* Search Cruises Form */}
				<TabContent
					label={
						<>
							<ShipIcon /> {searchCruisesLabel}
						</>
					}
				>
					<div className="pb-6">
						<form className="flex flex-col gap-2 2xl:flex-row" onSubmit={handleCruiseSubmit(onSubmitCruise)}>
							<div ref={pickersWrapperRef} className="flex flex-1 flex-col gap-2 md:flex-row">
								<Controller
									name="departureFrom"
									control={cruiseControl}
									render={() => (
										<DatePickerWrapper
											departureList={options.departureList}
											// onStartDateChange={handleStartDateChange} // onEndDateChange={handleEndDateChange}
											pickersWrapperRef={pickersWrapperRef.current && pickersWrapperRef?.current}
										/>
									)}
								/>
								<Controller
									name="adults"
									control={cruiseControl}
									render={() => (
										<GuestCabinPicker
											options={guestOptions}
											totalPassengers={totalPassengers}
											cabins={1} // Assuming 1 cabin as it's not in the store
											summaryLabel={totalPassengers > 1 ? occupancyLabelPlural : occupancyLabelSingular}
											cabinLabel={occupancyLabelCabinSingular}
										/>
									)}
								/>
							</div>
							<div className="flex gap-2">
								<div className="relative flex md:hidden h-auto">
									{activeFiltersIndex > 0 && <div className="flex justify-center items-center absolute z-10 top-1 right-1 bg-secondary text-white text-sm font-bold rounded-full translate-x-1/2 -translate-y-1/2 w-5 h-5">{activeFiltersIndex}</div>}
									<DropdownFilter leftIcon={<FiltersIcon />} negative={true} onClick={() => setOpenAllFilters(!openAllFilters)} />
								</div>
								<Button type="submit" disabled={!isSearchButtonEnabled} isSubmitting={isSubmitting} variant="filled" className="w-full 2xl:max-w-80" icon={<IoSearchSharp />}>
									{searchCruisesLabel}
								</Button>
							</div>
						</form>
					</div>
					<hr className="hidden md:block bg-background h-0.5" />
					<SearchCruiseFilters openAllFilters={openAllFilters} setOpenAllFilters={setOpenAllFilters} setActiveFiltersIndex={setActiveFiltersIndex} />
				</TabContent>

				{/* Search MSC Voyagers Club Member Form */}
				<TabContent
					label={
						<div className="flex items-center gap-2" onClick={() => sendVoyagerClubGA4Event('discover_program', 'check_member_status', 'MSC Voyagers Club Cruises')}>
							<VoyagerIcon /> {searchVoyagerClubLabel}
						</div>
					}
				>
					<div>
						<form className="flex flex-col gap-2" onSubmit={handleVoyagerSubmit(onSubmitVoyagerClub)}>
							<div className="flex flex-1 flex-col md:flex-row gap-2">
								{searchVoyagersSchema.map((schemaItem, index) => (
									<div className="w-full" key={index}>
										<FormFactory control={voyagerControl} errors={voyagerErrors} {...schemaItem} />
									</div>
								))}
							</div>
							{showVoyagerErrorMessage && <p className="font-semibold">{voyagerMemberNotFound}</p>}
							<hr className="bg-background h-0.5 mt-3 mb-2" />
							<div className="flex flex-col md:flex-row justify-end gap-3 pb-4">
								<Button variant="outlined" className="w-full 2xl:max-w-fit" onClick={handleDiscoverProgramClick}>
									{discoverProgramLabel}
								</Button>
								<Button type="submit" variant="filled" className="w-full 2xl:max-w-fit" onClick={() => sendVoyagerClubGA4Event('discoverstatusmscvcm', 'check_member_status', 'MSC Voyagers Club Cruises')} isSubmitting={isSubmitting}>
									{checkMemberStatusLabel}
								</Button>
							</div>
						</form>
					</div>
				</TabContent>
			</Tabs>
		</div>
	)
}
