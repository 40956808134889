'use client'
import React, { useState } from 'react'
import Image from 'next/image'
import delete_remove_backspace from '../../../../public/images/delete_remove_backspace.png'
import bold_text_option from '../../../../public/images/bold-text-option.png'

interface TextAreaBYFProps {
	textAreaLabel: string
	characterCounterText: string
	currentTypedCharacters: number
	textAreaMaxLength: number
	value: string
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
	rows: number
	isBoldIconVisible?: boolean
	onBoldIconClick?: () => void
}

const TextAreaBYF = ({ textAreaLabel, characterCounterText, currentTypedCharacters, textAreaMaxLength, value, onChange, rows, isBoldIconVisible = false, onBoldIconClick }: TextAreaBYFProps) => {
	const [isBold, setIsBold] = useState(false)
	const toggleBold = () => {
		setIsBold((prev) => !prev)
	}
	return (
		<div>
			<div className="flex items-center justify-between relative mb-4">
				<label htmlFor="additional-paragraph">{textAreaLabel}</label>
				<div className="flex items-center justify-between gap-2">
					{isBoldIconVisible && (
						<Image
							src={bold_text_option}
							className="cursor-pointer"
							alt="bold icon"
							width={14}
							height={14}
							objectFit="contain"
							layout="fixed"
							onClick={() => {
								toggleBold()
								onBoldIconClick?.()
							}}
						></Image>
					)}
					<Image src={delete_remove_backspace} alt="delete remove backspace icon" width={24} height={24} objectFit="contain" layout="fixed"></Image>
				</div>
			</div>
			<div className="flex flex-col gap-2.5">
				<textarea className="border-[1px] p-5 border-[#BDBDBD] focus:outline-none rounded-lg" value={value} onChange={onChange} rows={rows} maxLength={textAreaMaxLength} style={{ fontWeight: isBold ? 'bold' : 'normal' }}></textarea>
				<div className="flex justify-between">
					<p>{characterCounterText}</p>
					<p className="font-bold">
						{currentTypedCharacters}/{textAreaMaxLength}
					</p>
				</div>
			</div>
		</div>
	)
}

export { TextAreaBYF }
