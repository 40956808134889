'use client'

import { customStyles } from '@/components/atoms/SelectWrapper'
import { KeysOverride } from '@/features/sitecore/models/keys-override'
import Select from 'react-select'
import { DevTools } from '../configs/devToolsConfigs'
import { useDevToolsState } from '../store/useDevToolsState'

export const SitecoreKeysOverride = () => {
	const options = Object.keys(KeysOverride).map((name) => ({ value: name, label: `Keys override: ${name.toLowerCase()}` }))
	const store = useDevToolsState()

	const changeValue = async (value: string | undefined) => {
		if (!value) return
		store.set(DevTools.SITECORE_KEYS_OVERRIDE, value)
	}

	return (
		<div className="flex gap-3">
			{/* eslint-disable-next-line prettier/prettier */}
			{/* prettier-ignore */}
			<Select
				options={options}
				placeholder="Override the sitecore values"
				onChange={(option) => changeValue(option?.value)}
				styles={customStyles}
				className="w-full"
				defaultValue={options.find((option) => option.value === store.sitecoreKeysOverride)}
			/>
		</div>
	)
}
