'use client'

import React from 'react'
import { DevTools, DevToolsStyling } from '../configs/devToolsConfigs'
import { useDevToolsState } from '../store/useDevToolsState'

export interface BundleNameViewProps {
	bundleName?: string
}

export const BundleNameView: React.FC<BundleNameViewProps> = (props) => {
	const devToolsState = useDevToolsState()
	if (!devToolsState.showBundleNames || !props.bundleName) return <></>

	const colorClass: string = DevToolsStyling[DevTools.SHOW_BUNDLE_NAMES]

	const copyToClipboard = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.stopPropagation()
		navigator.clipboard.writeText(props.bundleName!)
		console.debug(`Copied "${props.bundleName}" to clipboard`)
	}

	return (
		<span className={`dev-tools-bundle-name cursor-pointer ${colorClass}`} title="click to copy" onClick={copyToClipboard}>
			{props.bundleName}
		</span>
	)
}
