'use client'
import React, { useState } from 'react'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { Button, Input, OrangeCheckbox, SwiperWrapperBYF } from '@/components/uiComponents'

// ------------------------------------Mock DATA-----------------------------------
const promotionsMock = [
  {
    text: 'Fly & Cruise',
    iconSrc: '/images/mock/flight_included_icon_p282.jpg',
  },
  {
    text: 'Drink Package',
    iconSrc: '/images/mock/drink_packages_icon_p282.jpg',
  },
  {
    text: 'Internet Included',
    iconSrc: '/images/mock/internet_icon_p282.jpg',
  },
  {
    text: 'On board credit',
    iconSrc: '/images/mock/onboard_credit_euro_logo_pos.jpg',
  },
  {
    text: 'Transfer Included',
    iconSrc: '/images/mock/bus_icon_p282.jpg',
  },
]

const imageContainerMock = ['/images/mock/drinks_pack_no_alcohol_logo_pos.jpg', '/images/mock/fly_cruise_logo_pos.jpg', '/images/mock/onboard_credit_euro_logo_pos.jpg']

// ------------------------------------Mock DATA-----------------------------------

const PromotionsStep = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const [promotionButtonClicked, setPromotionButtonClicked] = useState<number | undefined>(undefined)
	const [isChecked, setIsChecked] = useState<boolean>(false)
	const [textareaText, setTextareaText] = useState<string>('')

	const handlePromotionNameClick = (promotionName: string, index: number) => {
		const promotionText = document.getElementById('promotion-text')
		const promotionIcon = document.getElementById('promotion-icon')
		if (promotionText && promotionIcon) {
			promotionText.innerHTML = promotionName.toUpperCase()
			promotionIcon.setAttribute('src', promotionsMock[index].iconSrc)
			setPromotionButtonClicked(index)
		}
	}

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked)
	}

	const formatPrice = (value: string) => {
		const cleanedValue = value.replace(/[^\d]/g, '')
		return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	}

	const handleTotalPriceOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const totalPrice = document.getElementById('total-price')
		const newValue = event.target.value
		const formattedValue = formatPrice(newValue)
		if (totalPrice) {
			totalPrice.innerHTML = formattedValue
			setTextareaText(formattedValue)
		}
	}

	const applyPromotionLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-APPLY-PROMOTION', 'Apply a promotion')
	const addPriceLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-ADD-PRICE', 'Add Price')
	const priceOverwriteLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-PRICE-OVERWRITE', '*This new price will overwrite the previous price details')
	const promoImageLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-PROMO-IMAGE', 'Promo image')
	const replaceWithPromoImageLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-REPLACE-DEST-WITH-PROMO-IMG', 'Replace the destination image with promotional image')
	const selectPromoLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-SELECT-PROMO-IMG', 'Select promo image')
	const typeThePriceLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PROMOTION_STEP-SET-PRICE', 'Set price')

	return (
		<div className="flex flex-col gap-10 w-[372px]">
			<h2 className="text-sm font-bold uppercase">{applyPromotionLabel}</h2>
			<div className="grid grid-cols-2 gap-4">
				{promotionsMock?.map((promotion, index) => {
					return (
						<Button
							key={index}
							onClick={() => {
								handlePromotionNameClick(promotion.text, index)
							}}
							variant={promotionButtonClicked === index ? 'filledSecondary' : 'outlinedTertiary'}
							className="h-14"
						>
							<span className="text-sm leading-5">{promotion.text}</span>
						</Button>
					)
				})}
			</div>
			{/* <div className="flex flex-col gap-6">
				<p>{addPriceLabel}</p>
				<Input type="text" label={typeThePriceLabel} customClasses="!rounded-md" onChange={handleTotalPriceOnChange} value={textareaText} />
				<p>{priceOverwriteLabel}</p>
			</div> */}
			<p>{promoImageLabel}</p>
			<span className="text-sm max-w-[263px]">
				<OrangeCheckbox isChecked={isChecked} handleIsChecked={handleCheckboxChange} textNextToCheckbox={replaceWithPromoImageLabel} />
			</span>
			{isChecked && (
				<>
					<p>{selectPromoLabel}</p>
					<div>
						<SwiperWrapperBYF imageContainerMock={imageContainerMock} htmlElementIdToChangeIcon='promotion-image'/>
					</div>
				</>
			)}
		</div>
	)
}

export { PromotionsStep }
