'use client'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { ExploraLink, FooterLinks, FooterSocialLinks, SeparatorText } from '../uiComponents'

const Footer = () => {
	const user = useLoggedUser()
	const sitecoreStore = useSitecoreState((state) => state)
	const loggedInFooterCss = !user ? '' : 'xl:ml-24 2xl:ml-32'
	const mscGroupLabel = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-MSC-GROUP-HEADER-TEXT', 'MSC Group')

	return (
		<div className={`flex flex-col justify-center items-center bg-msc-blue text-white pt-5 pb-5 ${loggedInFooterCss}`}>
			<FooterLinks />
			<SeparatorText>{mscGroupLabel}</SeparatorText>
			<ExploraLink />
			<FooterSocialLinks />
		</div>
	)
}

export { Footer }
