import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { useModalState } from '@/utils/hooks/useModalState'
import React from 'react'
import { ActionLink, Button, FlyerBuilderNavigationButton, InfoIcon } from '../uiComponents'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

export interface FlyerBuilderStepperProps {
	children: string[]
}

const FlyerBuilderStepper = ({ children }: FlyerBuilderStepperProps) => {
	const modalState = useModalState()
	const { activeStep, setActiveStep } = useFlyerBuilderState()
	// const isLastStep = activeStep >= children.length
	// const sitecoreStore = useSitecoreState((state) => state)
	const sitecoreStore = useSitecoreState((state) => state)


	const closeLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-CLOSE', 'Close')
	const buildYourFlyerLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-MSC-BUILD_YOUR_FLYER', 'Build your flyer')
	const readInstructionsLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-READ-INSTRUCTIONS', 'Read instructions')
	const instructionsMessageLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-INSTRUCTIONS-MESSAGE', 'This flyer builder is designed to convey specific commercial information about MSC offers. It allows you to select the itinerary, the visual, the ship, the map, and the timing of the departure you want to promote. You can customize the description of your offer, including packages and promotions. Additionally, you can include your logo and contact details.')

	return (
		<div className="flex items-center px-6 bg-white shadow-sm">
			<div className="flex flex-col justify-center py-6 gap-6">
				<div className="flex gap-4">
					<h2 className="uppercase text-msc-blue text-xl font-bold">{buildYourFlyerLabel}</h2> 
					<ActionLink
						icon={<InfoIcon size="12" color="#035FBF" />}
						onClick={() => {
							modalState.openInfoModal({
								variant: 'close',
								children: instructionsMessageLabel,
							})
						}}
					>
						{readInstructionsLabel}
					</ActionLink>
				</div>
				<div className="flex flex-col xl:flex-row gap-4">
					<div className="flex gap-6">
						{children.map((child, index) => {
							// Start title label mock - Replace all this part with interpolated labels
							// Needs sitecore key
							let title
							if (child === 'destination') {
								title = 'Choose a destination'
							} else if (child === 'itinerary') {
								title = 'Select an itinerary'
							} else if (child === 'promotions') {
								title = 'Apply a promotion'
							} else if (child === 'agentDetails') {
								title = 'Add Travel Agents details'
							} else if (child === 'notes') {
								title = 'Additional notes'
							} else if (child === 'flyer') {
								title = 'Download flyer'
							}
							// End title label mock - Replace all this part with interpolated labels
							return (
								<React.Fragment key={index}>
									<FlyerBuilderNavigationButton isActive={index + 1 === activeStep} isDisabled={index + 1 > activeStep} buttonId={child} onClick={() => setActiveStep(index + 1)}>{`${index + 1}. ${title}`}</FlyerBuilderNavigationButton>
								</React.Fragment>
							)
						})}
					</div>
				</div>
			</div>
			<div className="ml-auto">
				<Button variant="outlined">{closeLabel}</Button>
			</div>
		</div>
	)
}

export { FlyerBuilderStepper }
