'use client'
import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import SwiperCore, { Swiper as SwiperType } from 'swiper'
import Image from 'next/image'
import forward_arrow_white from '../../../../public/images/forward_arrow_white.png'
import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { DynamicPicture } from '@/components/atoms/DynamicPicture'

// -------------------------------------MOCK DATA---------------------------------------------------
// const imageContainerMock = ['https://www.mscbook.com/images/sdl/B2B_PROMONEW_confidence_350x170_87-125742.jpg', 'https://www.mscbook.com/images/sdl/FUGA24-350X170_it_IT.jpg', 'https://www.mscbook.com/images/sdl/MSC-Book-OC-CAR-350x170_it_IT.jpg', 'https://www.mscbook.com/images/sdl/B2B_PROMONEW_confidence_350x170_87-125742.jpg']

// const imageContainerMock = ['/images/mock/msc-1.jpg', '/images/mock/msc-2.jpg', '/images/mock/msc-3.jpg', '/images/mock/msc-4.jpg']
// -------------------------------------MOCK DATA---------------------------------------------------
interface SwiperWrapperBYFProps {
	imageContainerMock: string[]
	htmlElementIdToChangeIcon: string
}

const SwiperWrapperBYF = ({ imageContainerMock, htmlElementIdToChangeIcon }: SwiperWrapperBYFProps) => {
	const flyerBuilderState = useFlyerBuilderState()
	const [isBeginning, setIsBeginning] = useState(true)
	const [isEnd, setIsEnd] = useState(false)
	const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null)

	const swiperRef = useRef<SwiperCore | null>(null)
	const handlePrevSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev()
			setIsBeginning(swiperRef.current.isBeginning)
			setIsEnd(swiperRef.current.isEnd)
		}
	}
	const handleNextSlide = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext()
			setIsBeginning(swiperRef.current.isBeginning)
			setIsEnd(swiperRef.current.isEnd)
		}
	}

	const onSwiperInit = (swiper: SwiperType) => {
		swiperRef.current = swiper
		setIsBeginning(!swiper.isBeginning)
		setIsEnd(swiper.isEnd)
	}

	const onSlideChange = () => {
		if (swiperRef.current) {
			setIsBeginning(swiperRef.current.isBeginning)
			setIsEnd(swiperRef.current.isEnd)
		}
	}

	const handleSelectImageClick = (destinationIconSrc: string, index: number) => {
		const newIndex = activeImageIndex === index ? null : index
		setActiveImageIndex(newIndex)

		flyerBuilderState.updateStepData('destination', {
			selectedImgindex: newIndex,
			imageSrc: newIndex !== null ? destinationIconSrc : null,
		})

		const destinationImageElement = document.getElementById(htmlElementIdToChangeIcon) as HTMLImageElement
		if (destinationImageElement) {
			destinationImageElement.src = newIndex !== null ? '/images/' + destinationIconSrc : ''
		}
	}

	return (
		<Swiper
			onSwiper={onSwiperInit}
			onSlideChange={onSlideChange}
			slidesPerView={3}
			spaceBetween={8}
			style={{ maxWidth: '450px' }}
			navigation={{
				prevEl: '.custom-prev',
				nextEl: '.custom-next',
			}}
		>
			{imageContainerMock.map((src: string, index: number) => {
				console.log(src)
				return (
					<SwiperSlide key={index}>
						<DynamicPicture style={{ width: '150px', height: '80px', objectFit: 'cover' }} width={150} height={80} image={src} alt="" className="cursor-pointer border-2 rounded" onClick={() => handleSelectImageClick(src, index)} />
						{flyerBuilderState.steps.destination.selectedImgindex === index && <div className="absolute rounded border-4 border-black inset-0 bg-gradient-to-b from-transparent via-[rgba(0,0,0,0.197621)] to-[#000033] pointer-events-none z-0"></div>}
					</SwiperSlide>
				)
			})}

			{!isBeginning && (
				<button onClick={handlePrevSlide} className="custom-prev absolute left-1 top-1/2 transform -translate-y-1/2 z-10">
					<Image src={forward_arrow_white} alt="Previous" className="w-8 h-8 rotate-180" />
				</button>
			)}

			{!isEnd && (
				<button onClick={handleNextSlide} className="custom-next absolute right-1 top-1/2 transform -translate-y-1/2 z-10">
					<Image src={forward_arrow_white} alt="Next" className="w-8 h-8" />
				</button>
			)}
		</Swiper>
	)
}

export { SwiperWrapperBYF }
