import { KeysOverride } from '@/features/sitecore/models/keys-override'
import { create } from 'zustand'
import { DevTools } from '../configs/devToolsConfigs'

export interface DevToolsState {
	enabled: boolean
	sitecoreKeysOverride: KeysOverride
	showBundleNames: boolean
}

export interface DevToolsStateFunctions {
	set: (key: DevTools, def: any) => void
}

export const createDevToolsStore = (enabled: boolean) => {
	return create<DevToolsState & DevToolsStateFunctions>((set) => ({
		enabled,
		sitecoreKeysOverride: KeysOverride.NONE,
		showBundleNames: false,

		set: async (key, val) => {
			set({ [key]: val })
		},
	}))
}
