const mockedFlyerTemplate = `<main style="display: flex; flex-direction: column; width: 100%">
    <section style="width: 100%; background-color: #182541; padding: 8px 0">
        <div style="height: 35px; margin: 8px auto;">
            <img src="/images/msc-cruises-logo.svg" style="width: 150px; height: 40px; margin: 0 auto;" alt="Logo"></img>
        </div>
    </section>
    <section id="flyer-visual" style="position: relative; width: 100%; height: 200px; background-color: #5ac5f3; background-size: cover; background-position: center center">
        <img id="flyer-visual-img" src="https://archive.org/download/placeholder-image/placeholder-image.jpg" style="width: 100%; border: 2px solid #add8e6; height: 100%; object-fit: cover; object-position: center center;">
        <p id="choose-destination" style="position: absolute; top: 12px; left: 50%; transform: translateX(-50%); margin: 0; text-align: center; color: black; z-index: 1; font-size: 16px; font-weight: 700;"></p>
    </section>
    <section id="title-section" style="text-align: center; margin: 8px 0; max-width: 640px">
        <p id="flyer-title" style="font-size: 20px; line-height: 1.1; color: #000033; margin: 8px auto; text-transform: uppercase">DISCOVER THE BEAUTY OF LA VALLETTA, BARCELLONA, MARSEILLE AND BEYOND</p>
        <p id="flyer-description" style="text-align: center; line-height: 1.2; color: #000033; margin: 8px 0">Be captivated by the unique allure of the West Mediterranean. Sail in glorious comfort on the MSC World Europa for a unique cruise lasting seven nights around the region's iconic landmarks and most wondrous hidden gems.</p>
    </section>
    <!-- If only one itinerary -->
    <section id="flyer-itinerary-single">
        <div style="display: flex; gap: 16px">
            <img src="https://archive.org/download/placeholder-image/placeholder-image.jpg" style="width: 270px; height: 150px; border: 2px solid #add8e6; border-radius: 8px; object-fit: cover; background-position: center center;" class="itinerary-image"></img>
            <div style="flex: 1">
                <p id="destination-text" style="color: #EE732F; padding-bottom: 5px; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">Destination name</p>
                <p id="ship-text" style="color: #000033; padding-bottom: 5px; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">MSC Ship name</p>
                <p style="font-size: 14px; padding-bottom: 5px; line-height: 1.2;">Departure from <span id="port-text">XXXXXXXXX</span></p>
                <p style="font-size: 14px; line-height: 1.2; padding-bottom: 5px;">On <span id="departure-day-text">XXXXXXXXX</span></p>
                <p style="font-size: 14px; line-height: 1.2; padding-bottom: 5px;"><span id="date-range">XXX - XXX</span></p>
                <p style="font-size: 14px; line-height: 1.2; padding-bottom: 5px;"><span id="days-nights">XXXX</span></p>
                <div style="display: flex; align-items: center; justify-content: flex-start; gap: 6px">
                    <img id="promotion-icon" style="height: 24px; width: 24px; border: 1px solid #add8e6;" src="https://archive.org/download/placeholder-image/placeholder-image.jpg"/>
                    <p id="promotion-text"style="color: #000033; font-weight: 700; text-transform: uppercase">Package included</p>
                </div>
            </div>
        </div>
        <div style="display: flex; align-items: center; padding: 8px; margin-top: 24px; border-radius: 16px; border: 1px solid #000033;">
            <div style="flex: 1; padding: 0px 16px">
                <img id="promotion-image" src="https://archive.org/download/placeholder-image/placeholder-image.jpg" style="background-color: #5ac5f3; height: 45px; width: 80px;" />
            </div>
            <div style="padding: 0px 16px;">
                <p style="font-size: 13px; line-height: 1.1;">Starting from 
                    <span style="font-size: 20px; line-height: 1.1;">€599</span>
                 p.p. + Hotel service charge €40 p.p.</p>
            </div>
            <div style="display: none; flex: 2; padding: 0px 16px; text-align: center">
                <p style="font-size: 13px; line-height: 1.1;; font-weight: 700">TOTAL PRICE FROM</p>
                <p style="font-weight: 700">
                    <span style="font-size: 30px; line-height: 1.1;">€</span>
                    <span style="font-size: 30px; line-height: 1.1;" id="total-price">0.000</span> p.p.</p>
            </div>
        </div>
        <p id="below-price-paragraph" style="font-size: 12px; line-height: 1.2; color: #000033; margin: 8px 0; text-align: center"></p>
    </section>
   
    <section style="width: 100%; background-color: #182541; padding: 8px 0">
        <div style="display: flex; height: 10px; margin: 8px auto; justify-content: space-around; align-items: center;">
            <img src="https://www.mscbook.com/pages/sdl/img/B2B_TA_EURIBIA_visual_1920x465px.jpg" style="width: 120px;" alt="Logo"></img>
            <p style="color: white; font-size: 12px;">Book now with your travel advisor | Visit msccruises.com</p>
        </div>
    </section>
   
    <section id="last-paragraph">
        <div style="display: flex; width: 100%; padding: 5px; box-sizing: border-box;">
            <div style="flex: 1; display: flex; align-items: center; justify-content: center; padding: 5px;">
            <span id="travelAgencyName" style="font-size: 24px; font-weight: bold;">Travel Agency</span>
        </div>
        <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding: 5px;">
            <p style="margin-bottom: 5px; font-size: 12px;">Name: <span id="name"></span></p>
            <p style="margin-bottom: 5px; font-size: 12px;">Email: <span id="email"></span></p>
            <p style="margin-bottom: 5px; font-size: 12px;">Phone Number: <span id="phone"></span></p>
        </div>
    </section>
  
    <!-- If multiple itinerary -->
    <section id="flyer-itinerary-multiple" style="min-height:152px; display: none; margin-bottom: 15px">
        <div style="display: flex; flex-direction: row; align-items: center; gap: 24px">
            <img class="itinerary-image" src="https://archive.org/download/placeholder-image/placeholder-image.jpg" style="flex: 1; border: 2px solid #add8e6; border-radius: 8px; background-color: #d4edfc; background-size: cover; background-position: center center; width: 220px"></img>
            <div style="flex: 1.5">
                <p class="destination-text" style="color: #EE732F; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">Destination name</p>
                <p class="ship-text" style="color: #000033; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">MSC Ship name</p>
                <p style="font-size: 14px; line-height: 1.2;"><span class="day-start">3 october 2025</span> for <span class="nights-length-text">7</span> nights</p>
                <div>
                    <p style="font-size: 14px; line-height: 1.2; font-weight: 700; text-transform: uppercase;"> 
                    <span class="promotion-text"style="color: #000033; font-weight: 700; text-transform: uppercase">Package included</span>
                    </p>
                </div>
                <div style="display: flex; align-items: center; padding: 8px; margin-top: 8px; border-radius: 16px; border: 1px solid #000033;">
                   
                    <div style="flex: 2; padding: 0px 16px; text-align: center">
                        <p style="font-weight: 700">
                    <span style="font-size: 30px; line-height: 1.1;">€</span>
                    <span style="font-size: 30px; line-height: 1.1;" id="total-price">0.000</span>
                     p.p.</p>
                        <p style="font-size: 11px; line-height: 1;">Luggages included</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>`

export default mockedFlyerTemplate

// mock flyer, all Figma elements copy

// const mockedFlyerTemplate = `<main style="display: flex; flex-direction: column; width: 100%">
//     <section style="display: flex; align-items: center; justify-content: center;width: 100%; background-color: #182541; padding: 24px 0">
//         <img src="https://int.mscbook.com/pages/sdl/img/B2B_TA_Logo_neg_header.svg" style="margin: 8px auto; width: 150px; height: auto;" alt="Logo""></img>
//     </section>
//     <section id="flyer-visual" style="position: relative; width: 100%; height: 200px; background-color: #5ac5f3; background-size: cover; background-position: center center">
//         <img id="flyer-visual-img" src="https://archive.org/download/placeholder-image/placeholder-image.jpg" style="width: 100%; border: 2px solid #add8e6; height: 100%; object-fit: cover; background-position: center center;">
//         <p id="choose-destination" style="position: absolute; top: 12px; left: 50%; transform: translateX(-50%); margin: 0; text-align: center; color: black; z-index: 1; font-size: 16px; font-weight: 700;"></p>
//     </section>
//     <section id="title-section" style="text-align: center; margin: 8px 0">
//         <p id="flyer-title" style="font-size: 20px; line-height: 1.1; color: #000033; max-width: 440px; margin: 8px auto; text-transform: uppercase">Saectecae verum con ero et enistrunt lab idendit asperibus.</p>
//         <p id="flyer-description" style="line-height: 1.2; color: #000033; margin: 8px 0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//     </section>
//     <!-- If only one itinerary -->
//     <section id="flyer-itinerary-single">
//         <div style="display: flex; gap: 16px">
//             <img src="https://archive.org/download/placeholder-image/placeholder-image.jpg" style="width: 300px; border: 2px solid #add8e6; height: 180px; object-fit: cover; background-position: center center;" class="itinerary-image"></img>
//             <div style="flex: 1">
//                 <p id="destination-text" style="color: #EE732F; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">Destination name</p>
//                 <p id="ship-text" style="color: #000033; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">MSC Ship name</p>
//                 <p style="font-size: 14px; line-height: 1.2;">Departure from <span id="port-text">XXXXXXXXX</span></p>
//                 <p style="font-size: 14px; line-height: 1.2;">On <span id="xxxxxx-text">XXXXXXXXX</span></p>
//                 <p style="font-size: 14px; line-height: 1.2;">From <span id="from-text">month</span> to <span id="to-day-text">month</span>, <span id="to-year-text">Year</span></p>
//                 <p style="font-size: 14px; line-height: 1.2;"><span id="days-legth-text">XX</span> days - <span id="nights-length-text">XX</span> nights</p>
//                 <p id="promotion-text"style="color: #000033; font-weight: 700; text-transform: uppercase">Package included</p>
//             </div>
//         </div>
//         <div style="display: flex; align-items: center; padding: 8px; margin-top: 24px; border-radius: 16px; border: 1px solid #000033;">
//             <div style="flex: 1; padding: 0px 16px">
//                 <img src="" style="width: auto; height: 60px; background-color: #5ac5f3;" />
//             </div>
//             <div style="flex: 2; padding: 0px 16px; border-left: 1px solid #000033; border-right: 1px solid #000033">
//                 <p style="font-size: 13px; line-height: 1.1;">Starting from <span style="font-size: 20px; line-height: 1.1;">€799</span> p.p. + Hotel service charge €60 p.p.</p>
//             </div>
//             <div style="flex: 2; padding: 0px 16px; text-align: center">
//                 <p style="font-size: 13px; line-height: 1.1;; font-weight: 700">TOTAL PRICE FROM</p>
//                 <p style="font-weight: 700">
//                     <span style="font-size: 30px; line-height: 1.1;">€</span>
//                     <span style="font-size: 30px; line-height: 1.1;" id="total-price">0.000</span>
//                  p.p.</p>
//             </div>
//         </div>
//         <p id="below-price-paragraph" style="font-size: 10px; line-height: 1.2; color: #000033; margin: 8px 0; text-align: center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget metus nec odio ultricies ultricies. Nullam at purus vitae metus ultricies ultricies. Sed eget metus nec odio ultricies ultricies. Nullam at purus vitae met</p>
//     </section>

//     <section>
//         <div style="display: flex; width: 100%; padding: 5px; box-sizing: border-box;">
//             <div style="flex: 1; display: flex; align-items: center; justify-content: center; padding: 5px;">
//             <span id="travelAgencyName" style="font-size: 24px; font-weight: bold;">Travel Agency</span>
//         </div>
//         <div style="flex: 1; display: flex; flex-direction: column; justify-content: center; padding: 5px;">
//             <p style="margin-bottom: 5px; font-size: 12px;">Name: <span id="name"></span></p>
//             <p style="margin-bottom: 5px; font-size: 12px;">Email: <span id="email"></span></p>
//             <p style="margin-bottom: 5px; font-size: 12px;">Phone Number: <span id="phone"></span></p>
//         </div>
//     </section>

//     <!-- If multiple itinerary -->
//     <section id="flyer-itinerary-multiple" style="display: block; margin-bottom: 15px">
//         <div style="display: flex; flex-direction: row; align-items: center; gap: 24px">
//             <img class="itinerary-image" src="" style="flex: 1; height: 180px; border-radius: 8px; background-color: #d4edfc; background-size: cover; background-position: center center; width: 270px"></img>
//             <div style="flex: 1.5">
//                 <p class="destination-text" style="color: #EE732F; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">Destination name</p>
//                 <p class="ship-text" style="color: #000033; font-weight: 700; text-transform: uppercase; font-size: 15px; line-height: 1.2;">MSC Ship name</p>
//                 <p style="font-size: 14px; line-height: 1.2;"><span class="day-start">3 october 2025</span> for <span class="nights-length-text">7</span> nights</p>
//                 <div>
//                     <p style="font-size: 14px; line-height: 1.2; font-weight: 700; text-transform: uppercase;">
//                     <span class="promotion-text"style="color: #000033; font-weight: 700; text-transform: uppercase">Package included</span>
//                     </p>
//                 </div>
//                 <div style="display: flex; align-items: center; padding: 8px; margin-top: 8px; border-radius: 16px; border: 1px solid #000033;">
//                     <div style="flex: 1; padding: 0px 8px; border-right: 1px solid #000033">
//                         <img src="" class="itinerary-img" style="width: auto; height: 60px; background-color: #5ac5f3;" />
//                     </div>
//                     <div style="flex: 2; padding: 0px 16px; text-align: center">
//                         <p style="font-size: 11px; line-height: 1; font-weight: 700; text-transform: uppercase">Starting from</p>
//                         <p style="font-weight: 700"><span style="font-size: 30px; line-height: 1.1;" class="total-price">€0.000</span> p.p.</p>
//                         <p style="font-size: 11px; line-height: 1;">Luggages included</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </section>
// </main>`

// export default mockedFlyerTemplate
