'use client'
import React, { useState } from 'react'
import Image from 'next/image'
import delete_remove_backspace from '../../../../public/images/delete_remove_backspace.png'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { Input } from '@/components/uiComponents'

const AgentDetailsStep = () => {
	const sitecoreStore = useSitecoreState((state) => state)
	const [inputData, setInputData] = useState<{ [key: string]: string }>({
		travelAgencyName: '',
		name: '',
		email: '',
		phone: '',
	})

	const agencyContactLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-YOUR-AGENCY-CONTACT', 'Add your Agency Contact (Otional)')
	const namePlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-NAME-PLACEHOLDER', 'Name')
	const emailPlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-EMAIL-PLACEHOLDER', 'Email')
	const phoneNumberPlaceholderLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PHONE-NUMBER-PLACEHOLDER', 'Phone number')
	const travelAgencyNameLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-PHONE-NUMBER-PLACEHOLDER', 'Travel agency name')

	const agentDetailsinputData = [
		{ type: 'text', placeholder: travelAgencyNameLabel, name: 'travelAgencyName' },
		{ type: 'text', placeholder: namePlaceholderLabel, name: 'name' },
		{ type: 'email', placeholder: emailPlaceholderLabel, name: 'email' },
		{ type: 'tel', placeholder: phoneNumberPlaceholderLabel, name: 'phone' },
	]

	const handleInputChange = (name: string, value: string) => {
		setInputData((prev) => ({ ...prev, [name]: value }))
		const element = document.getElementById(name)
		if (element) {
			element.innerHTML = value
		}
	}

	return (
		<div className="flex flex-col gap-6 w-[372px]">
			<div className="flex items-center justify-between relative">
				<label htmlFor="additional-paragraph">{agencyContactLabel}</label>
				<Image src={delete_remove_backspace} alt="delete remove backspace icon" width={24} height={24} objectFit="contain" layout="fixed"></Image>
			</div>
			{agentDetailsinputData.map((input, index) => (
				<Input key={index} type={input.type} label={input.placeholder} customClasses="!rounded-md" name={input.name} onChange={(e) => handleInputChange(input.name, e.target.value)} value={inputData[input.name]} />
			))}
		</div>
	)
}

export { AgentDetailsStep }
