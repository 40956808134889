import { useFlyerBuilderState } from '@/utils/hooks/useFlyerBuilderState'
import { Button, FlyerBuilderStepperProps } from '../uiComponents'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

const FlyerBuilderStepperControls = ({ children }: FlyerBuilderStepperProps) => {
	const { activeStep, setActiveStep } = useFlyerBuilderState()
	const isLastStep = activeStep >= children.length
	const sitecoreStore = useSitecoreState((state) => state)
	const flyerBuilderState = useFlyerBuilderState()

	const isNextButtonActive = () => {
		if (isLastStep) {
			return true
		} else if (activeStep === 1) {
			return flyerBuilderState.steps.destination.isPolicyAccepted ? false : true
		}
	}

	const nextLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-NEXT', 'Next')
	const backLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-BACK', 'Back')
	return (
		<div className="container">
			<div className="flex flex-col justify-center py-6 gap-6">
				<div className="flex justify-end gap-4 ml-auto">
					{activeStep !== 1 && (
						<Button variant="outlined" onClick={() => setActiveStep(activeStep - 1)}>
							{backLabel}
						</Button>
					)}
					<Button variant="filled" disabled={isNextButtonActive()} onClick={() => !isLastStep && setActiveStep(activeStep + 1)}>
						{nextLabel}
					</Button>
				</div>
			</div>
		</div>
	)
}

export { FlyerBuilderStepperControls }
