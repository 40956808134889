'use client'
import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { Promotion } from '@/features/sitecore/actions/promotions'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import { useModalState } from '@/utils/hooks/useModalState'
import { useUrlProvider } from '@/utils/url/hooks/useUrlProvider'
import Link from 'next/link'
import React from 'react'
import 'swiper/css'
import { useViewportType } from '../ui-hooks/useViewportType'
import { CardPromotions, SwiperWrapper } from '../uiComponents'

interface PromotionsSectionTemplateProps {
	promotionData: Promotion[]
}

const PromotionsSectionTemplate = (props: PromotionsSectionTemplateProps) => {
	const viewport = useViewportType()
	const user = useLoggedUser()
	const market = useCurrentMarket()
	const provideUrl = useUrlProvider()
	const viewAllPromotionsLink = provideUrl('all-promotions')

	const renderPromos = () => {
		return props.promotionData.map((promotion, index) => {
			return (
				<React.Fragment key={index}>
					<CardPromotions index={index} promotion={promotion} />
				</React.Fragment>
			)
		})
	}
	//Translation labels
	const sitecoreStore = useSitecoreState((state) => state)
	const allPromotionsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-ALL_PROMOTION', 'All promotions')
	const viewAll = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_VIEW_ALL', 'View all')

	// GA4
	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendviewAllPromotionsLabelClickGA4Event = () => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'viewallpromo',
			eventName: 'view_all_promo',
			...staticParams,
			label_clicked: 'View all',
		})
	}

	const handleViewAllPromotionsClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		sendviewAllPromotionsLabelClickGA4Event()
		if (!user) {
			e.preventDefault()
			useModalState.getState().openLoginModal({ nextUrl: viewAllPromotionsLink })
		}
	}

	return (
		<section className="max-w-full">
			<div className="flex flex-row justify-between">
				<h2 className="font-extrabold text-msc-blue text-xl">{allPromotionsLabel}</h2>
				<Link prefetch={false} href={viewAllPromotionsLink} className="font-bold text-msc-blue cursor-pointer" onClick={handleViewAllPromotionsClick}>
					{viewAll}
				</Link>
			</div>
			<div className="w-full">
				{viewport !== 'isDesktop' && viewport !== 'isDesktopLarge' && viewport !== 'isDesktopZoomed' && viewport !== 'isDesktopXL' ? (
					<SwiperWrapper slidesToShow={{ base: 1.2, sm: 2.2, lg: 3 }} overflow="visible" pt="24px">
						{renderPromos()}
					</SwiperWrapper>
				) : (
					<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 pt-9">{renderPromos()}</div>
				)}
			</div>
		</section>
	)
}

export { PromotionsSectionTemplate }
