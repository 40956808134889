'use client'
import React, { useState } from 'react'
import Image from 'next/image'
import orangeCheckbox from '../../../../public/images/orangeCheckbox.png'

interface OrangeCheckboxType {
	isChecked: boolean
	handleIsChecked: (e: React.ChangeEvent<HTMLInputElement>) => void
	textNextToCheckbox?: string
}

const OrangeCheckbox = ({ isChecked, handleIsChecked, textNextToCheckbox }: OrangeCheckboxType) => {
	return (
		<div className="flex gap-2.5 items-center">
			<div className="relative w-5 h-5">
				<input type="checkbox" name="replace-destination-img" height={20} width={20} onChange={handleIsChecked} checked={isChecked} disabled={false} className={`cursor-pointer ${isChecked ? 'opacity-0' : 'opacity-100'}`} style={{ width: '100%', height: '100%' }} />
				{isChecked && <Image src={orangeCheckbox} alt="checkbox" height={20} width={22} objectFit="contain" layout="fixed" className="absolute bottom-1 right-0 pointer-events-none" />}
			</div>
			<span className="text-sm max-w-sm">{textNextToCheckbox}</span>
		</div>
	)
}

export { OrangeCheckbox }
