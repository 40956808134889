import { DevToolsState } from '@/features/dev-tools/store/dev-tools-state'
import { Bundle } from '@/features/sitecore/actions/services/sitecore-bundle'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { getOverridedLabelText } from '@/features/sitecore/lib/labels-override'
import { create } from 'zustand'
import { KeysOverride } from '../models/keys-override'

export interface SitecoreState {
	devToolsState: DevToolsState | undefined
	bundles: Record<string, Bundle>
	getLabel: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => string
	getConfig: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => string
	getMultiKeyLabelAsString: (
		keysAndDefaults: Array<{
			bundle: TranslationBundles
			key: string
			default?: string
			replacement?: string[]
		}>
	) => Record<string, string>
	getNumber: (bundle: TranslationBundles, key: string, defaultNumber: number, allowedValues?: number[]) => number
	getBundle: (bundle: TranslationBundles) => Bundle
}

export interface SitecoreStoreInitialState {
	bundles: { [key: string]: Bundle }
}

export const createSitecoreStore = (bundles: Record<string, Bundle>) => {
	return create<SitecoreState>((set, get) => ({
		devToolsState: undefined,
		bundles,

		getLabel: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => {
			const fullBundle = get().bundles[bundle]
			if (!fullBundle) {
				console.warn(`Bundle ${bundle} not found`)
				return defaultLabel
			}

			const sitecoreKeysOverride = get().devToolsState?.sitecoreKeysOverride || KeysOverride.NONE
			let label = getOverridedLabelText(sitecoreKeysOverride, key) ?? fullBundle[key] ?? defaultLabel
			if (!fullBundle[key]) console.warn(`Key ${key} not found in bundle ${bundle}`)

			if (fullBundle[key] === `???${key}???`) {
				console.warn(`Key ${key} in bundle ${bundle} has not been set`)
				return defaultLabel
			}

			replacements?.forEach((replacement, index) => {
				label = label.replace(`{${index}}`, replacement)
			})
			return label
		},

		getConfig: (bundle: TranslationBundles, key: string, defaultLabel: string, replacements?: string[]) => {
			const fullBundle = get().bundles[bundle]
			if (!fullBundle) {
				console.warn(`Bundle ${bundle} not found`)
				return defaultLabel
			}

			let label = fullBundle[key] ?? defaultLabel
			if (!fullBundle[key]) console.warn(`Key ${key} not found in bundle ${bundle}`)

			if (fullBundle[key] === `???${key}???`) {
				console.warn(`Key ${key} in bundle ${bundle} has not been set`)
				return defaultLabel
			}

			replacements?.forEach((replacement, index) => {
				label = label.replace(`$${index}`, replacement)
			})
			return label
		},

		getMultiKeyLabelAsString: (keysAndDefaults) => {
			return keysAndDefaults.reduce(
				(acc, { bundle, key, default: defaultValue = '', replacement }) => {
					acc[key] = get().getLabel(bundle, key, defaultValue, replacement)
					return acc
				},
				{} as Record<string, string>
			)
		},

		getNumber: (bundle: TranslationBundles, key: string, defaultNumber: number, allowedValues?: number[]) => {
			const fullBundle = get().bundles[bundle]
			if (!fullBundle) {
				console.warn(`Bundle ${bundle} not found`)
				return defaultNumber
			}

			if (!fullBundle[key]) console.warn(`Key ${key} not found in bundle ${bundle}`)

			if (fullBundle[key] === `???${key}???`) {
				console.warn(`Key ${key} in bundle ${bundle} has not been set`)
				return defaultNumber
			}

			const labelValue = fullBundle[key]
			if (!labelValue) return defaultNumber

			const numericValue = Number(labelValue)

			if (isNaN(numericValue)) {
				console.warn(`Invalid numeric value for key ${key} in bundle ${bundle}`)
				return defaultNumber
			}

			if (allowedValues && !allowedValues.includes(numericValue)) {
				console.warn(`Value ${numericValue} not in allowed values for key ${key} in bundle ${bundle}`)
				return defaultNumber
			}

			return numericValue
		},

		getBundle: (bundle: TranslationBundles) => get().bundles[bundle],
	}))
}
