'use client'

import useLoggedUser from '@/features/auth/hooks/useLoggedUser'
import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { staticGA4ParamsFromUser } from '@/utils/analytics/googleAnalyticsHelper'
import exploraLogo from '@public/images/explora_logo.png'
import Image from 'next/image'
import Link from 'next/link'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const ExploraLink = () => {
	const market = useCurrentMarket()
	const user = useLoggedUser()
	const sitecoreStore = useSitecoreState((state) => state)

	// GA4
	const staticParams = staticGA4ParamsFromUser(user, market)
	const sendFooterLinkGA4Event = (labelClicked: string) => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event: 'footerLink',
			eventName: 'footer_Link',
			...staticParams,
			label_clicked: labelClicked,
		})
	}

	const footerExploraLink = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-MSC-GROUP-LINK-URL-1', '#')
	const footerExploraLinkText = sitecoreStore.getLabel(TranslationBundles.FOOTER, 'MSC-FOOTER-MSC-GROUP-LINK-TEXT-1', 'Introducing the new Luxury cruise brand')

	return (
		<Link prefetch={false} className="flex flex-col items-center justify-center lg:flex-row justify-center" href={footerExploraLink}>
			<div className="flex border-0 pb-2 lg:after:content-['|'] after:pl-2 lg:pb-0">
				<Image src={exploraLogo} alt="explora logo"></Image>
			</div>
			<div className="flex items-center gap-1">
				<span className="pl-2 cursor-pointer" onClick={() => sendFooterLinkGA4Event('Introducing the new Luxury cruise brand')}>
					{footerExploraLinkText}
				</span>
				<MdOutlineKeyboardArrowRight size={22} />
			</div>
		</Link>
	)
}

export { ExploraLink }
