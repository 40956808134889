'use client'
import React, { useState } from 'react'
// import { useElasticFilterState } from '@/features/search-cruise/store/useElasticFilterState'
// import { getPriceB2BMinPriceWithHSC } from '@/features/pricesb2b/hooks/get-price-b2-b-min-price-with-h-s-c'
import { Button, SingleItenerary } from '@/components/uiComponents'
import add_box from '../../../../public/images/add_box.png'
import Image from 'next/image'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'

const ItineraryStep = () => {
	// const ships = useElasticFilterState((state) => state.options.ship)
	// const minimumPriceWithHSC = getPriceB2BMinPriceWithHSC({ departureDateFrom: '28/11/2024', departureDateTo: '30/11/2024', shipCd: 'FA' })
	const [itineraries, setItineraries] = useState([{ id: 0 }])
	const [isFirstClick, setIsFirstClick] = useState(true)
	const sitecoreStore = useSitecoreState((state) => state)

	const selectItineraryLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-SELECT-AN-ITINERARY', 'Select an itinerary')
	const addItineraryLabel = sitecoreStore.getLabel(TranslationBundles.BUILD_YOUR_FLYER, 'MSC-BUILD_YOUR_FLYER-ADD-ITIN', 'Add itinerary')

	const handleAddItinerary = () => {
		setItineraries((prev) => [...prev, { id: prev.length }])
		const singleItinContainer = document.getElementById('flyer-itinerary-single')
		const newSingleMultipleItinerary = document.getElementById('flyer-itinerary-multiple')
		const belowPriceParagraph = document.getElementById('below-price-paragraph')
		const lastParagraph = document.getElementById('last-paragraph')

		if (singleItinContainer && newSingleMultipleItinerary) {
			const cloneItinerary = newSingleMultipleItinerary.cloneNode(true) as HTMLElement
			cloneItinerary.style.display = 'block'

			const newSingleMultipleItineraryHtml = cloneItinerary.outerHTML
			const currentId = itineraries.length

			if (isFirstClick) {
				singleItinContainer.innerHTML = ''
				const newItineraryHtml1 = newSingleMultipleItineraryHtml.replace('flyer-itinerary-multiple', `flyer-itinerary-multiple-${currentId}`)
				const newItineraryHtml2 = newSingleMultipleItineraryHtml.replace('flyer-itinerary-multiple', `flyer-itinerary-multiple-${currentId + 1}`)
				singleItinContainer.innerHTML = newItineraryHtml1 + newItineraryHtml2
				if (belowPriceParagraph && lastParagraph) {
					const paragraphHTML = belowPriceParagraph.outerHTML
					lastParagraph.insertAdjacentHTML('afterend', paragraphHTML)
					setIsFirstClick(false)
				}
			} else {
				const newItineraryHtml = newSingleMultipleItineraryHtml.replace('flyer-itinerary-multiple', `flyer-itinerary-multiple-${currentId + 1}`)
				singleItinContainer.insertAdjacentHTML('beforeend', newItineraryHtml)
			}
		}
	}

	const handleRemoveItinerary = (idToRemove: number) => {
		setItineraries((prev) => prev.filter(({ id }) => id !== idToRemove))
		const singleItemMultipleItin = document.getElementById(`flyer-itinerary-multiple-${idToRemove + 1}`)
		if (singleItemMultipleItin) {
			singleItemMultipleItin.remove()
		}
	}

	return (
		<div className="w-[372px] min-h-[700px] max-h-[900px] overflow-y-auto">
			<div className="flex justify-between items-center mb-10">
				<h2 className="text-sm font-bold uppercase">{selectItineraryLabel}</h2>

				<Button variant="outlined" className="text-sm" disabled={itineraries.length === 5 ? true : false} onClick={handleAddItinerary}>
					{addItineraryLabel}
					<Image src={add_box} height={20} width={20} alt={''}></Image>
				</Button>
			</div>

			{itineraries.map(({ id }) => (
				<SingleItenerary key={id} itenNumber={id + 1} onRemove={() => handleRemoveItinerary(id)} />
			))}
		</div>
	)
}

export { ItineraryStep }
