'use client'

import { ReactElement, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useViewportType } from '../ui-hooks/useViewportType'
import { DeparturesIcon, ReturnIcon, TabButton, TabList } from '../uiComponents'

import useCurrentMarket from '@/features/caching/hooks/useCurrentMarket'
import { TranslationBundles } from '@/features/sitecore/configs/translation-bundles'
import { useSitecoreState } from '@/features/sitecore/store/useSitecoreState'
import { endOfMonth, isValid, parse, startOfMonth } from 'date-fns'

// Import all required locales
import { de } from 'date-fns/locale/de'
import { enGB } from 'date-fns/locale/en-GB'
import { es } from 'date-fns/locale/es'
import { fr } from 'date-fns/locale/fr'
import { it } from 'date-fns/locale/it'
import { nl } from 'date-fns/locale/nl'
import { pt } from 'date-fns/locale/pt'
import { tr } from 'date-fns/locale/tr'
import { startOfToday } from 'date-fns/startOfToday'
import { useElasticFilterState } from '@/features/elastic/store/useElasticFilterState'
import { ElasticFilterState, IOption } from '@/features/elastic/store/types'

// Register all required locales
registerLocale('en-GB', enGB)
registerLocale('es', es)
registerLocale('de', de)
registerLocale('fr', fr)
registerLocale('nl', nl)
registerLocale('pt', pt)
registerLocale('it', it)
registerLocale('tr', tr)

const localesMap = {
	'en-GB': enGB,
	es: es,
	de: de,
	fr: fr,
	nl: nl,
	pt: pt,
	it: it,
	tr: tr,
}
type LocaleKey = keyof typeof localesMap

// Market code to locale mapping
const marketLocaleMap: { [key: string]: string } = {
	// Americas
	ARG: 'es',
	BOL: 'es',
	BRA: 'pt',
	CAN: 'en-GB',
	CHL: 'es',
	COL: 'es',
	CRI: 'es',
	ECU: 'es',
	MEX: 'es',
	NIC: 'es',
	PAN: 'es',
	PRY: 'es',
	PER: 'es',
	URY: 'es',
	USA: 'en-GB',
	VEN: 'es',

	// Europe
	AUT: 'de',
	BEL: 'fr',
	BGR: 'en-GB',
	CZE: 'en-GB',
	CRO: 'en-GB',
	CYP: 'en-GB',
	DNK: 'en-GB',
	FIN: 'en-GB',
	FRA: 'fr',
	GER: 'de',
	GBR: 'en-GB',
	GRE: 'en-GB',
	HUN: 'en-GB',
	IRL: 'en-GB',
	ITA: 'it',
	LVA: 'en-GB',
	LTU: 'en-GB',
	HOL: 'nl',
	NOR: 'en-GB',
	POL: 'en-GB',
	POR: 'pt',
	ROM: 'en-GB',
	RUS: 'en-GB',
	SPA: 'es',
	SWE: 'en-GB',
	SWI: 'de',
	TUR: 'tr',
	UKR: 'en-GB',

	// Asia Pacific
	AUS: 'en-GB',
	BGD: 'en-GB',
	CHI: 'en-GB',
	IND: 'en-GB',
	IDN: 'en-GB',
	JPN: 'en-GB',
	KOR: 'en-GB',
	MYS: 'en-GB',
	NZL: 'en-GB',
	SGP: 'en-GB',
	LKA: 'en-GB',
	THA: 'en-GB',

	// Middle East & Africa
	EGY: 'en-GB',
	ISR: 'en-GB',
	JOR: 'en-GB',
	LBN: 'en-GB',
	MAR: 'en-GB',
	QAT: 'en-GB',
	ZAF: 'en-GB',
	TUN: 'en-GB',
	ARE: 'en-GB',
}

type DateRange = {
	start: Date
	end: Date
}

const generateDateRanges = (dates: Date[]): DateRange[] => {
	if (dates.length === 0) return []

	const sortedDates = [...dates].sort((a, b) => a.getTime() - b.getTime())
	const ranges: DateRange[] = []
	let currentRangeStart = sortedDates[0]

	for (let i = 1; i < sortedDates.length; i++) {
		const currentDate = sortedDates[i]
		const nextDay = new Date(currentRangeStart)
		nextDay.setDate(nextDay.getDate() + 1)

		if (currentDate.getTime() !== nextDay.getTime()) {
			ranges.push({
				start: new Date(currentRangeStart.setHours(0, 0, 0, 0)), // Start of the range at midnight
				end: new Date(sortedDates[i - 1].setHours(23, 59, 59, 999)), // End of the range at 23:59:59.999 of the previous day
			})
			currentRangeStart = currentDate
		}
	}

	// Push the last range
	ranges.push({
		start: new Date(currentRangeStart.setHours(0, 0, 0, 0)),
		end: new Date(sortedDates[sortedDates.length - 1].setHours(23, 59, 59, 999)),
	})

	return ranges
}

const generateMonthRanges = (dates: Date[]): Date[] => {
	if (dates.length === 0) return []

	const monthMap = new Map<string, Date>()

	dates.forEach((date) => {
		const monthKey = `${date.getFullYear()}-${date.getMonth()}`
		if (!monthMap.has(monthKey)) {
			monthMap.set(monthKey, endOfMonth(date))
		}
	})

	return Array.from(monthMap.values()).sort((a, b) => a.getTime() - b.getTime())
}

const getLocaleByMarket = (marketCode: string): string => {
	return marketLocaleMap[marketCode] || 'en-GB'
}

const convertDateString = (dateString: string) => {
	const [day, month, year] = dateString.split('/')
	return `${year}-${month}-${day}`
}

// Custom hook to memoize converted dates
// const useConvertedDates = (departureList: IOption[]) => {
// 	return useMemo(() => {
// 		return departureList.map((el) => {
// 			const dateParts = el.label.split('/')
// 			return new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))
// 		})
// 	}, [departureList])
// }

interface DatePickerWrapperProps {
	departureList: IOption[]
	pickersWrapperRef?: HTMLDivElement | null
}

const DatePickerWrapper = ({ departureList, pickersWrapperRef }: DatePickerWrapperProps) => {
	const viewport = useViewportType()
	const today = startOfToday()

	const { departureTo, departureFrom, setDateFilter } = useElasticFilterState((state: ElasticFilterState) => ({
		departureTo: state.options.departureTo,
		departureFrom: state.options.departureFrom,
		setDateFilter: state.setDateFilter,
	}))

	const market = useCurrentMarket()
	const sitecoreStore = useSitecoreState((state) => state)

	const departureFromDate = departureFrom && new Date(convertDateString(departureFrom))
	const departureToDate = departureTo && new Date(convertDateString(departureTo))
	const currentYear = new Date().getFullYear()

	const departureLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_DEPARTURE', 'Departure')
	const returnLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-MSC_RETURN', 'Return')
	const searchCalendarNoteLabel = sitecoreStore.getLabel(TranslationBundles.CRUISE_RESULTS, 'MSC-CRUISERESULTS-SENTENCE_BEFORE_CRUISE_RESULTS', 'The maximum search period is 4 months')
	// const isMonthViewEnabled = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_ENABLE_MONTH_VIEW', 'false')
	const isMonthViewEnabled = 'true'
	const isMonthTabDefault = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_MONTH_TAB_DEFAULT', 'false')
	// const isCalendarMultiple = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_MULTIPLE_VIEW', 'false')
	const isCalendarMultiple = 'true'
	const monthsLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_MONTHS', 'Months')
	const daysLabel = sitecoreStore.getLabel(TranslationBundles.HOME, 'MSC-HOMEPAGE-CALENDAR_DAYS', 'Days')

	const [monthsToShow, setMonthsToShow] = useState(3)
	const [includeDates, setIncludeDates] = useState<DateRange[]>([])
	const [includeMonths, setIncludeMonths] = useState<Date[]>([])
	const [startDate, setStartDate] = useState<Date | undefined>(undefined)
	const [endDate, setEndDate] = useState<Date | undefined>(undefined)
	const [isCalendarOpen, setIsCalendarOpen] = useState(false)
	const [locale, setLocale] = useState<string>(getLocaleByMarket(market.code) || 'en-GB')
	const [shouldShowMonthsView, setShouldShowMonthsView] = useState(isMonthTabDefault === 'true' && isMonthViewEnabled === 'true')
	const [isDropdownJustOpened, setIsDropdownJustOpened] = useState(false)

	// Inside DatePickerWrapper component
	const lastAvaiableDate = departureList.map((option) => new Date(convertDateString(option.label))).reduce((latest, current) => (latest > current ? latest : current), new Date(0))

	const maxDate = lastAvaiableDate

	const calendarRef = useRef<HTMLDivElement>(null)
	const departureInputRef = useRef<HTMLInputElement>(null)
	const returnInputRef = useRef<HTMLInputElement>(null)
	const calendarSwitchRef = useRef<HTMLDivElement>(null)
	const calendarLabelRef = useRef<HTMLParagraphElement>(null)

	const dayLangOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}

	const handleBothDateChange = (dateFrom: Date | undefined, dateTo: Date | undefined) => {
		setDateFilter(dateFrom, dateTo)
	}

	useEffect(() => {
		if (departureFromDate) {
			setStartDate(departureFromDate)
		}
		if (departureToDate) {
			setEndDate(departureToDate)
		}
		const marketLocale = getLocaleByMarket(market.code)
		setLocale(marketLocale)
	}, [])

	useEffect(() => {
		if (viewport === 'isMobile') {
			setMonthsToShow(2)
		} else if (viewport === 'isTablet') {
			setMonthsToShow(2)
		} else if (viewport === 'isDesktop') {
			isCalendarMultiple === 'true' ? setMonthsToShow(3) : setMonthsToShow(2)
		} else if (viewport === 'isDesktopZoomed') {
			setMonthsToShow(2)
		} else if (viewport === 'isDesktopLarge') {
			isCalendarMultiple === 'true' ? setMonthsToShow(3) : setMonthsToShow(2)
		} else {
			isCalendarMultiple === 'true' ? setMonthsToShow(4) : setMonthsToShow(2)
		}
	}, [viewport])

	useEffect(() => {
		const convertedDates = generateDateRanges(departureList.map((el) => new Date(el.label)))
		const convertedMonths = generateMonthRanges(departureList.map((el) => new Date(el.label)))
		setIncludeDates(convertedDates)
		setIncludeMonths(convertedMonths)
	}, [departureList])

	const updateDates = () => {
		if (departureInputRef.current !== document.activeElement) {
			if (departureInputRef.current) {
				departureInputRef.current.value = startDate?.toLocaleDateString(locale, dayLangOptions) || ''
			}
		}
		if (returnInputRef.current !== document.activeElement) {
			if (returnInputRef.current) {
				returnInputRef.current.value = endDate?.toLocaleDateString(locale, dayLangOptions) || ''
			}
		}
	}

	//called when click on date inside calendar
	const handleDateChange = (dates: [Date | null, Date | null]) => {
		const [start, end] = dates

		// Handle start date
		let finalStart: Date | undefined
		if (start) {
			if (shouldShowMonthsView) {
				// In month view, use the first possible date of the selected month
				const firstDayOfMonth = startOfMonth(start)
				finalStart = firstDayOfMonth < today ? today : firstDayOfMonth
			} else {
				// In day view, use today if selecting current date or earlier
				finalStart = start < today ? today : start
			}
		}

		// Handle end date
		let finalEnd: Date | undefined
		if (finalStart && end) {
			// Calculate maximum allowed end date (exactly 4 months from start)
			const maxEndDate = new Date(finalStart.getTime())

			maxEndDate.setMonth(maxEndDate.getMonth() + 4)
			maxEndDate.setDate(maxEndDate.getDate() - 1) // Subtract one day to make it exactly 4 months

			if (shouldShowMonthsView) {
				// Allow selection of current month as both start and end date
				if (start && end && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
					finalEnd = endOfMonth(end) > maxEndDate ? maxEndDate : endOfMonth(end)
				} else if (end > maxEndDate) {
					// If selected end date is beyond 4 months, set it to the max allowed
					finalEnd = endOfMonth(maxEndDate) > maxEndDate ? maxEndDate : endOfMonth(maxEndDate)
				} else {
					// Set end date to end of selected month
					finalEnd = endOfMonth(end) > maxEndDate ? maxEndDate : endOfMonth(end)
				}
			} else {
				// For day view, just ensure we don't exceed maxEndDate
				finalEnd = end > maxEndDate ? maxEndDate : end
			}
		}

		if (departureInputRef.current !== document.activeElement && returnInputRef.current !== document.activeElement) {
			setStartDate(finalStart)
			setEndDate(finalEnd)
			finalStart && finalEnd ? handleBothDateChange(finalStart, finalEnd) : null
		}
		if (isDropdownJustOpened) {
			setIsDropdownJustOpened(false)
		}
	}

	//called when writing to input textbox for dates
	const handleDateInputChange = (input: string, isStartDate: boolean) => {
		// Remove non-numeric characters
		const numericInput = input.replace(/\D/g, '')

		// Add slashes between day, month, and year
		let formattedInput = numericInput
		if (numericInput.length > 2) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2)}`
		}
		if (numericInput.length > 4) {
			formattedInput = `${numericInput.slice(0, 2)}/${numericInput.slice(2, 4)}/${numericInput.slice(4)}`
		}

		// Update the input field value
		if (isStartDate && departureInputRef.current) {
			departureInputRef.current.value = formattedInput
		} else if (returnInputRef.current) {
			returnInputRef.current.value = formattedInput
		}

		// Parse the date
		const localeObj = localesMap[locale as LocaleKey] || enGB
		const formats = ['dd/MM/yyyy', 'd MMMM yyyy']
		const inputWithYear = formattedInput.match(/\d{4}/) ? formattedInput : `${formattedInput} ${currentYear}`

		let parsedDate = null
		for (const format of formats) {
			parsedDate = parse(inputWithYear, format, new Date(), { locale: localeObj })
			if (isValid(parsedDate)) break
		}

		// Ensure the date is not before today
		if (parsedDate && parsedDate < today) {
			parsedDate = today
		}

		// Ensure the year is not lower than the current year
		if (parsedDate && parsedDate.getFullYear() < currentYear) {
			parsedDate.setFullYear(currentYear)
		}

		const date = isValid(parsedDate) ? parsedDate : undefined
		if (date) {
			const lastAvailableDate = startDate ? new Date(startDate) : undefined
			lastAvailableDate?.setMonth(lastAvailableDate.getMonth() + 4)

			if (startDate && date < startDate && !isStartDate) {
				date.setTime(startDate.getTime())
			} else if (lastAvailableDate && date > lastAvailableDate) {
				date.setTime(lastAvailableDate.getTime())
			}

			if (isStartDate) {
				setStartDate(date)
			} else {
				setEndDate(date)
				handleBothDateChange(startDate, date)
			}
		}
	}

	const handleBlur = (input: RefObject<HTMLInputElement>, date: Date | undefined) => {
		if (input.current) {
			input.current.value = date?.toLocaleDateString(locale, dayLangOptions) || ''
		}
	}

	const handleFocus = (input: RefObject<HTMLInputElement>) => {
		if (input.current) {
			input.current.value = ''
		}
	}

	const customInput = useMemo(
		() => (
			<div className="flex flex-1 justify-around py-2 px-5 text-sm text-light-purple border-msc-blue border focus:outline-none rounded-sm placeholder-transparent peer focus:ring-3 focus:ring-focus-blue">
				<div className="flex flex-1 items-center py-1 cursor-pointer">
					<div className="absolute pointer-events-none">
						<DeparturesIcon />
					</div>
					<input ref={departureInputRef} className="text-sm w-full text-msc-blue placeholder-msc-blue border-0 shadow-none pl-6 cursor-pointer" placeholder={departureLabel} defaultValue={startDate?.toLocaleDateString(locale, dayLangOptions) || ''} onFocus={() => handleFocus(departureInputRef)} onBlur={() => handleBlur(departureInputRef, startDate)} type="text" onChange={(e) => handleDateInputChange(e.target.value, true)} />
				</div>
				<div className="h-7 w-px bg-darker-grey mx-4"></div>
				<div className="flex flex-1 items-center py-1">
					<div className="absolute pointer-events-none">
						<ReturnIcon />
					</div>
					<input ref={returnInputRef} className="text-sm w-full text-msc-blue placeholder-msc-blue border-0 shadow-none pl-6 cursor-pointer" placeholder={returnLabel} defaultValue={endDate?.toLocaleDateString(locale, dayLangOptions) || ''} onFocus={() => handleFocus(returnInputRef)} onBlur={() => handleBlur(returnInputRef, endDate)} type="text" onChange={(e) => handleDateInputChange(e.target.value, false)} />
				</div>
			</div>
		),
		[startDate, endDate, locale, departureLabel, returnLabel]
	)

	useEffect(() => {
		if (isCalendarOpen) {
			const datePickerWrapper = calendarRef.current?.querySelector('.datePickerPopperWrapper')
			if (datePickerWrapper) {
				const computedStyle = window.getComputedStyle(datePickerWrapper)
				const transformMatrix = computedStyle.transform

				if (transformMatrix && transformMatrix !== 'none') {
					const matrixValues = transformMatrix.match(/matrix.*\((.+)\)/)
					if (matrixValues && matrixValues[1]) {
						matrixValues[1].split(', ')
					}
				}
			}
		}
	}, [isCalendarOpen])

	const CalendarContainer = ({ className, children }: { className: string; children: ReactElement }) => {
		return (
			<div
				className={`relative ${isMonthViewEnabled === 'true' ? 'datePickerPadding' : ''} ${className}`}
				style={{
					maxWidth: monthsToShow === 2 && pickersWrapperRef?.clientWidth ? `${pickersWrapperRef?.clientWidth + 32}px` : 'auto',
				}}
			>
				{isMonthViewEnabled === 'true' && (
					<div ref={calendarSwitchRef} className="absolute top-4">
						<TabList reversedTabs={isMonthTabDefault !== 'true'}>
							<TabButton isActive={shouldShowMonthsView} onClick={() => setShouldShowMonthsView(true)}>
								<p className="text-sm">{monthsLabel}</p>
							</TabButton>
							<TabButton isActive={!shouldShowMonthsView} onClick={() => setShouldShowMonthsView(false)}>
								<p className="text-sm">{daysLabel}</p>
							</TabButton>
						</TabList>
					</div>
				)}
				{children}
				{<p ref={calendarLabelRef} className={`absolute left-0 bottom-0 translate-y-full w-full text-xs bg-white px-6 pb-5 text-right`} dangerouslySetInnerHTML={{ __html: searchCalendarNoteLabel }} />}
			</div>
		)
	}

	const enableOnlyFourMonthsAfterSelectedDate = (date: Date) => {
		const startingDate = startDate
		if (!startingDate || isDropdownJustOpened) return true

		const fourMonthsAfter = new Date(startingDate)
		fourMonthsAfter.setMonth(fourMonthsAfter.getMonth() + 4)

		return date >= new Date() && date <= fourMonthsAfter
	}

	const popperModifiers = [
		{
			name: 'offset',
			fn(state: { y: number; placement: string }) {
				if (state.placement === 'top-start' && calendarLabelRef.current) {
					state.y = state.y - calendarLabelRef.current.clientHeight
				}

				return state
			},
		},
	]
	const calcOpeningDate = () => {
		if (!startDate || !endDate) return new Date()
		if (startDate.getMonth() < endDate.getMonth() || startDate.getFullYear() < endDate.getFullYear()) {
			const adjustedStartDate = new Date(startDate)
			adjustedStartDate.setMonth(startDate.getMonth() + 1)

			return adjustedStartDate
		}

		return startDate
	}

	return (
		<div ref={calendarRef} className="flex-1 w-full z-50">
			<DatePicker
				selected={startDate}
				shouldCloseOnSelect={true}
				onCalendarOpen={() => {
					setIsCalendarOpen(true)
					setIsDropdownJustOpened(true)
				}}
				onCalendarClose={() => {
					setIsCalendarOpen(false)
					updateDates()
				}}
				onChange={handleDateChange}
				openToDate={calcOpeningDate() || new Date()}
				startDate={startDate}
				endDate={endDate}
				calendarContainer={CalendarContainer}
				popperClassName="w-full datePickerPopperWrapper"
				includeDateIntervals={!shouldShowMonthsView ? includeDates : undefined}
				includeDates={!shouldShowMonthsView ? undefined : includeMonths}
				popperPlacement="bottom-start"
				wrapperClassName="w-full"
				customInput={customInput}
				dateFormat="dd/MM/yyyy"
				filterDate={enableOnlyFourMonthsAfterSelectedDate}
				monthsShown={monthsToShow}
				selectsRange={true}
				locale={locale}
				minDate={startOfToday()}
				maxDate={maxDate}
				placeholderText="Departure from"
				popperModifiers={popperModifiers}
				showMonthYearPicker={shouldShowMonthsView}
				showFullMonthYearPicker={shouldShowMonthsView}
			/>
		</div>
	)
}

export { DatePickerWrapper }
